import React, { useContext, useEffect } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import {Line} from 'react-chartjs-2';

import { stakingToken } from "../../contracts/staking_token";
import { stakingContract } from "../../contracts/staking_contract";
import { walletState } from "../../utils/walletState";
import { useRecoilValue } from "recoil";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

const Statistics = () => {
  const { client,address } = useRecoilValue(walletState);

  useEffect(()=>{
    getStats();
  },[])

  const getStats = async()=>{
    try {
      const balanceRes = await client?.queryContractSmart(
        stakingToken.at,
        {
          transfer_history: {
            address: address,
            key:'co0F46RmZN9eX6gwZ48e167HF5r84vE0bAcOFc9g=',
            page: 1,    // page no
            page_size: 20,  // number of entries
          }
        }
      );
      console.log(balanceRes);
      
    } catch (error) {
      console.log(error);
      return;
      
    }
  }
  return (
    <div className="wrapper">
        <div className="contract-info-wrapper">
          <h2 className="info-heading">Liquid Analytics</h2>
          <div className="info-card card">
              <Line 
              data={{
                  labels:['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                  datasets:[
                      {
                    label:'JUNO',
                    data: [3434.123, 3978.68, 3456.587, 3098.576, 4567.897, 2674.789, 6789.567],
                    backgroundColor:'#f2545b',

                  },{
                    label:'seJUNO',
                    data: [4434.123, 5978.68, 6456.587, 6098.576, 4567.897, 3674.789, 4789.567],
                    backgroundColor:'white',
                    borderColor:'grey'
                  }
                ]
              }}
              height={100}
              width={200}
              color='red'
              
              
              />
              </div>
        </div>
    </div>
  )
}

export default Statistics