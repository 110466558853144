import React from "react";
import AccountInfo from "./account_info";

function Account(){
    return(
        <>
            <div className='wrapper'>
                <AccountInfo />
            </div>
        </>
    )
}

export default Account;