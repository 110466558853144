import {createContext} from "react";

interface PendingClaims {
    claim_time: number,
    juno_amount: string,
}

export const UserContext = createContext({
    isLoggingIn:<boolean>false, 
    setIsLoggingIn:(status:boolean)=>{},
    isMessageLoading:<any>false,
    seJunoBalance:<any>'',
    bJunoBalance:<any>'',
    getJunoBalance:()=>{},
    JunoBalance:<any>"",
    claimAmount:<any>0,
    bjunoxclaimAmount:<any>0,
    activeWindowAmount:<any>0,
    pendingClaims:<PendingClaims[]>[],
    bjunoxpendingClaims:<PendingClaims[]>[],
    totalPending:<any>undefined,
    bjunoxtotalPending:<any>undefined,
    getSeJunoBalance:()=>{},
    getBJunoBalance:()=>{},
    getClaimAmount:()=>{},
    getBjunoxClaimAmount:()=>{},
    getPendingClaims:()=>{},
    getActiveWindowAmount:()=>{}, 
    getBjunoxPendingClaims:()=>{},
    info:<any>{},
    apy:<any>"",
    activeWindow:<any>{},
    fetchWindow:()=>{},
    isWindowFetching:<any>false,
    isLoading:<any>false,
    rate:<any>'',
    bjunorate:<any>'',
    secretUnit:<any>'',
    toggleSecretUnit:(unit:string)=>{},
    resetBalances:()=>{},
    isWalkingThrough:<Boolean>false,
    isMobileUser:<Boolean>true,
    setIsWalkingThrough:(status:Boolean)=>{},
    currentWTPage:<number>0, 
    setCurrentWTPage:(page:number)=>{},
    token2For1Amount:<string>'',
    token1For2Amount:<string>'',
    getToken2ForToken1:(amount:string)=>{},
    getToken1ForToken2:(amount:string)=>{},
    token2For1Fees:<string>'',
    token1For2Fees:<string>'',
    poolTvl:<string>'',
    poolRatio:<number>1,
    junoPriceUsd:<number>0
})
//fetchFarmingInfo, getMyRewards, getMyDeposits,
//myRewards, myDeposits, farmingInfo