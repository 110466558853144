import React, { useState } from 'react'
import TransactionElement from '../../../../components/history_txn/transactionElement';

const TransactionHistory = (props:any) => {

    const [curPage, setCurPage] = useState(1);

    const handlePageChange=(event:any)=>{
        const id = event.target.id;
        if(id === "prev" && curPage>1){
          setCurPage(curPage-1);
        }else if(id==="next" && props.transactionHistory.length===20){
          setCurPage(curPage+1)
        }
      }
  
  return (
<div className="contract-info-wrapper">
          <h2 className="info-heading">Withdraw/Transfer History</h2>
            
          <div className="info-card card">
            {props.transactionHistory.length===0?<div style={{'margin':'0 auto'}}>No transactions.</div>:
             <div>{props.transactionHistory.map((t:any)=>{
               return <div>
                 <TransactionElement
                 id={t.id}
                 sender={t.sender}
                 receiver={t.receiver}
                 amount={t.coins.amount}
                 denom={t.coins.denom}
                 />
               </div>
             })}
             <div className="pagination-wrapper">
               
               <div onClick={handlePageChange}  id="prev" className="pagination-prev"
               style={{'color':`${curPage===1?'GrayText':'white'}`}}
               >{"<< Previous"}</div>
               - {curPage} -
               <div onClick={handlePageChange}  id="next" className="pagination-next"
               style={{'color':`${props.transactionHistory.length!==0?'GrayText':'white'}`}}
               >{"Next >>"}</div>
             </div>
             </div>
             }
          </div>
          </div>  )
}

export default TransactionHistory