import { useContext } from "react";
import { BalanceToggleContext } from "../../context/balanceToggleContext";
import Exchange from "./exchange";
import { Stats } from "./info";

function Stake() {
	const { balanceButtonToggle } = useContext(BalanceToggleContext);
	let toggleClass = "";

	if (
		(window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth <= 768) &&
		balanceButtonToggle
	) {
		toggleClass = " wrapperToggle";
	} else {
		toggleClass = " ";
	}

	return (
		<>
			<div className={`wrapper ${toggleClass}`}>
				<Stats />
				<div className='stake-child'>
					<Exchange />
				</div>
			</div>
		</>
	);
}

export default Stake;
