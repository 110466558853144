import './stake.css';
import { useContext, useState  } from 'react';
import StakeMenu from '../../utils/stakeMenu';
import { UserContext } from '../../context/user-context';
import {denomConst} from '../../utils/constants'

function Exchange(){
    const [stake, setStake] = useState<boolean>(true);
    const [unstake, setUnstake] = useState<boolean>(false);
    const [convert, setConvert] =useState<boolean>(false);
    const [inputAmount, setInputAmount] = useState<string | null>();
    const {secretUnit} = useContext(UserContext);

    const stakeMenuHandler = () => {
        setStake(true);
        setUnstake(false);
        setConvert(false);
    }
    const unStakeMenuHandler = () => {
        setStake(false);
        setUnstake(true);
        setConvert(false);
    }
    const convertMenuHandler = ()=>{
        setStake(false);
        setUnstake(false);
        setConvert(true);
        
    }
    return(
        <div className='exchange-wrapper card'>
            <div className='stake-menu-btn-wrapper'>
                <div onClick={stakeMenuHandler} className={stake ? `stake-menu-btn active-stake-btn`:`stake-menu-btn`}>STAKE</div>
                <div onClick={unStakeMenuHandler} className={!unstake ? `stake-menu-btn`:`stake-menu-btn active-stake-btn`}>UNSTAKE</div>
                <div onClick={convertMenuHandler} className={!convert ? `stake-menu-btn`:`stake-menu-btn active-stake-btn`}>CONVERT</div>
            </div>
            {
                stake ? (
                    <StakeMenu isConvert={convert} name={`Stake ${denomConst.tokenDenom}`} placeholder={denomConst.tokenDenom} inputAmount={inputAmount} setInputAmount={setInputAmount} />
                ):
                    <StakeMenu isConvert={convert} name='Unstake' placeholder={denomConst.derTokenDenom} inputAmount={inputAmount} setInputAmount={setInputAmount} />
                
            }
        </div>
    )
}

export default Exchange;