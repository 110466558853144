import React from 'react'
import { sleep } from "../../utils/constants";
import { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { stakingToken } from "../../contracts/staking_token";
import { rewardsContract } from "../../contracts/rewards_contract";
import { stakingContract } from "../../contracts/staking_contract";
import { walletState } from "../../utils/walletState";
import { useRecoilValue } from "recoil";
import { denomConst } from "../../utils/constants";
import { UserContext } from "../../context/user-context";
import LoadingSpinner from '../../components/loading_spinner/LoadingSpinner';
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import InfoBubble from "../../utils/information/InfoBubble";

  
const Rewards = (props:any) => {
  const {claimAmount,bjunoxclaimAmount,activeWindowAmount, pendingClaims, bjunoxpendingClaims,getPendingClaims,
    getClaimAmount, secretUnit, toggleSecretUnit,getBjunoxClaimAmount,getBjunoxPendingClaims,
  seJunoBalance, bJunoBalance,rate, isMessageLoading, activeWindow,getJunoBalance} = useContext(UserContext);


    const [loading, setLoading] = useState(false);
    const { client,address } = useRecoilValue(walletState);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [curPage, setCurPage] = useState(1);
    const [isCheckingAddress, setIsCheckingAddress] = useState(false);

    const CustomToastWithLink = (hash:string) => (
  <div> 
    <a style={{color:"blue",textDecoration:"none"}} href={"https://www.mintscan.io/juno/txs/"+hash} target="_blank" rel="noopener noreferrer">Click to view your transaction details {hash.substr(0,5) + '...' +hash.substr(hash.length-3,3)} </a> 
  </div>
);

    const doBjunoxClaim = async () => {
      setLoading(true);
      try {
       const res= await client?.execute(
          (address as string),
          rewardsContract.at,
          { 
            claim: {
            } 
          },
          {amount: [], gas: "500000"}, // custom fees
          "",
          []
        );

        setLoading(false);
        getBjunoxPendingClaims();
        getBjunoxClaimAmount();
        getJunoBalance();
        toast.success(`Successfully claimed ${bjunoxclaimAmount} ${secretUnit}.`,{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

          toast.info(CustomToastWithLink(res?(res.transactionHash).toString():"no hash"),{
          position: "top-right",
        hideProgressBar: false,
        autoClose: 10000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

      } catch (error) {
        setLoading(false);
        handleErrorMessage(error);
      }  
    }

    const handleErrorMessage = (error:any)=>{
      let errorMessage = "Something went wrong!";
        if (error instanceof Error) {
        errorMessage = error.message;
        }
        let displayMessage = errorMessage.lastIndexOf("Raw log:");
        if(displayMessage!==-1){
          errorMessage = errorMessage.slice(displayMessage);
        } 
        displayMessage = errorMessage.search("insufficient");
        if(displayMessage!==-1){
          errorMessage = errorMessage.slice(displayMessage,displayMessage+17).toUpperCase();
        }
        displayMessage = errorMessage.search("whitelisted");
        if (displayMessage!==-1) {
          errorMessage = "Address is not whitelisted.";
        }
        displayMessage = errorMessage.search("Network Error");
        if (displayMessage!==-1) {
          errorMessage = "Network Error.";
        }
        displayMessage = errorMessage.lastIndexOf("Raw log:")
        toast.error(`${errorMessage}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
    }
  return (
    <div className='wrapper'>
              <div className="contract-info-wrapper">
            <h2 className="info-heading">Claim Rewards</h2>
          <div className="info-card card">
            {!bJunoBalance?<div style={{'margin':'0 auto'}} >No amount staked</div>:
            <div className="staked-amount-wrapper">
                <div className="staked-amount-item">
                  <div className='amt-heading'>Unclaimed staking rewards</div>
                  <div className='unstaking-val'>
                    
                    <span>{bjunoxclaimAmount??(!address?<InfoBubble style={{'right':'0px','top':'-10px'}} 
            content='Connect Keplr wallet to see this balance' />:'-')}</span><span>{" JUNO "}</span>
                    
                    </div> 
                  <div className='amount-with-unit'>
                    </div>
                  </div>
                {/* <div className="staked-amount-item">
                  <div >Approx Total value <span style={{'color':'rgb(62, 207, 62)'}}>(+rewards)</span>:</div>
                    <div className='amount-with-unit'>
                      <span>{Number(bJunoBalance*rate).toFixed(6)} </span>
                      <span>{"bJUNOX"}</span>
                      </div>
                    </div> */}
            </div>}
            {/* <h2 className="info-heading">Claimable Rewards</h2> */}
            
          <div className="info-card">
            <>
            {null}
            {
              <div className="claimable-amount-wrapper" >
                <div className="claim-bjunox-rewards-btn"><button 

          disabled={ !bjunoxclaimAmount || isCheckingAddress || loading} 
          className= {'stake-btn account-btn'}
          style={{'width':'50%','margin':'0'}}
          onClick={doBjunoxClaim}
        >
          {isCheckingAddress?'Validating address...':loading ? 'Loading...' : `Claim Amount`}
        </button></div>
        </div>}
            </>
          
            </div>
          </div>
            </div>  
            </div>
    
  )
}

export default Rewards