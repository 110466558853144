import React from 'react'

const Rewards = (props:any) => {
  return (
    <div className="contract-info-wrapper">
            <h2 className="info-heading">{props.derTokenUnit} amount with rewards</h2>
          <div className="info-card card">
            {!props.derTokenBalance?<div style={{'margin':'0 auto'}} >No amount staked</div>:
            <div className="staked-amount-wrapper">
                <div className="staked-amount-item">
                  <div>Staked Amount</div> 
                  <div className='amount-with-unit'>
                    <span>{props.derTokenBalance.toLocaleString()} </span><span>{props.derTokenUnit}</span></div>
                  </div>
                <div className="staked-amount-item">
                  <div >Approx Total value <span style={{'color':'rgb(62, 207, 62)'}}>(+rewards)</span>:</div>
                    <div className='amount-with-unit'>
                      <span>{Number(props.derTokenBalance*props.rate).toFixed(6)} </span>
                      <span>{props.tokenUnit}</span>
                      </div>
                    </div>
            </div>}
            {props.bondingTokenBalance && 
            <div className="staked-amount-wrapper">
                <div className="staked-amount-item">
                  <div>Staked Amount</div> 
                  <div className='amount-with-unit'>
                    <span>{props.bondingTokenBalance.toLocaleString()} </span><span>{props.bondingTokenUnit}</span></div>
                  </div>
                <div className="staked-amount-item">
                  <div >Approx Total value <span style={{'color':'rgb(62, 207, 62)'}}>(+rewards)</span>:</div>
                    <div className='amount-with-unit'>
                      <span>{Number(props.bondingTokenBalance*props.rate).toFixed(6)} </span>
                      <span>{props.tokenUnit}</span>
                      </div>
                    </div>
            </div>}
          </div>
            </div>
  )
}

export default Rewards