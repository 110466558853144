import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";

import Header from "./components/header/header";
import Account from "./pages/account/account";
import ContractInfo from "./pages/contract_info/contract_info";
import Stake from "./pages/stake/stake";

import "react-toastify/dist/ReactToastify.css";
import DisclaimerModal from "./components/DisclaimerModal/DisclaimerModal";
import RightPane from "./components/right-pane/RightPane";
import { BalanceToggleContextProvider } from "./context/balanceToggleContext";
import { UserContext } from "./context/user-context";
import { useData } from "./hooks/useData";
import Defi from "./pages/defi/Defi";
import Rewards from "./pages/rewards/Rewards";
import Statistics from "./pages/statistics/statistics";
import Withdraw from "./pages/withdraw/Withdraw";

function App() {
	const data = useData();
	if (
		window.matchMedia &&
		window.matchMedia("(prefers-color-scheme: light)").matches
	) {
		document.getElementById("light-icon")?.remove();
	} else {
		document.getElementById("dark-icon")?.remove();
	}
	return (
		<div className='App'>
			<BalanceToggleContextProvider>
				<UserContext.Provider value={data}>
					<BrowserRouter>
						<DisclaimerModal />
						<Header />
						<Switch>
							<Route path='/info'>
								{" "}
								<ContractInfo />{" "}
							</Route>
							<Route path='/account'>
								{" "}
								<Account />{" "}
							</Route>
							<Route path='/statistics'>
								{" "}
								<Statistics />{" "}
							</Route>
							<Route path='/defi'>
								<Defi />
							</Route>
							{/* <Route path="/transactions"><TransactionHistory/></Route> */}
							<Route path='/withdraw'>
								<Withdraw />
							</Route>
							<Route path='/rewards'>
								<Rewards />
							</Route>
							<Route path='/'>
								{" "}
								<Stake />{" "}
							</Route>
						</Switch>
						<RightPane />
						{data.isWalkingThrough && data.currentWTPage < 9 ? (
							<div className='fade-out-components'></div>
						) : null}
						{/* <FaqBubble/> */}
						{/* <Socials/> */}
					</BrowserRouter>
				</UserContext.Provider>
			</BalanceToggleContextProvider>
		</div>
	);
}

export default App;
