import { sleep } from "../../utils/constants";
import { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { stakingToken } from "../../contracts/staking_token";
import { rewardsContract } from "../../contracts/rewards_contract";
import { stakingContract } from "../../contracts/staking_contract";
import { walletState } from "../../utils/walletState";
import { useRecoilValue } from "recoil";
import { denomConst } from "../../utils/constants";
import { UserContext } from "../../context/user-context";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import "./account.css"
import Rewards from "./sections/rewards/Rewards";
import Unstaking from "./sections/unstaking/Unstaking";
import Claimable from "./sections/claimable/Claimable";
import TransactionHistory from "./sections/transaction-history/TransactionHistory";

function AccountInfo(){

  const {claimAmount,bjunoxclaimAmount,activeWindowAmount, pendingClaims, bjunoxpendingClaims,getPendingClaims,
    getClaimAmount, secretUnit, toggleSecretUnit,getBjunoxClaimAmount,getBjunoxPendingClaims,
  seJunoBalance, bJunoBalance,rate, isMessageLoading, activeWindow} = useContext(UserContext);


    const [loading, setLoading] = useState(false);
    const { client,address } = useRecoilValue(walletState);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [curPage, setCurPage] = useState(1);
    const [isCheckingAddress, setIsCheckingAddress] = useState(false);

    useEffect(()=>{
      getStats();
    },[])
  
    const getStats = async()=>{
      try {
        while (address === undefined) await sleep(1);
        const historyRes = await client?.queryContractSmart(
          stakingToken.at,
          {
            transfer_history: {
              address: address,
              page: {curPage},    // page no
              page_size: 20,  // number of entries
            }
          }
        );
        console.log(historyRes);
        if(historyRes.transfer_history){
          setTransactionHistory(historyRes.transfer_history.txs);
        }
        
      } catch (error) {
        console.log(error);
        return;
        
      }
    }

    const doClaim = async () => {
      setLoading(true);
      try {
        await client?.execute(
          (address as string),
          stakingContract.at,
          { 
            claim: {}
          },
          {amount: [], gas: "700000"}
        );

        setLoading(false);
        getPendingClaims();
        getClaimAmount();
        toast.success(`Successfully claimed ${claimAmount} ${secretUnit}.`,{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          })
      } catch (error) {
        setLoading(false);
        handleErrorMessage(error);
      }  
    }

    const doBjunoxClaim = async () => {
      setLoading(true);
      try {
        await client?.execute(
          (address as string),
          rewardsContract.at,
          { 
            claim: {
              
            } 
          },
          {amount: [], gas: "700000"}
        );

        setLoading(false);
        getBjunoxPendingClaims();
        getBjunoxClaimAmount();
        toast.success(`Successfully claimed ${claimAmount} ${secretUnit}.`,{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          })
      } catch (error) {
        setLoading(false);
        handleErrorMessage(error);
      }  
    }

    const handleErrorMessage = (error:any)=>{
      let errorMessage = "Something went wrong!";
        if (error instanceof Error) {
        errorMessage = error.message;
        }
        let displayMessage = errorMessage.lastIndexOf("Raw log:");
        if(displayMessage!==-1){
          errorMessage = errorMessage.slice(displayMessage);
        } 
        displayMessage = errorMessage.search("insufficient");
        if(displayMessage!==-1){
          errorMessage = errorMessage.slice(displayMessage,displayMessage+17).toUpperCase();
        }
        displayMessage = errorMessage.search("whitelisted");
        if (displayMessage!==-1) {
          errorMessage = "Address is not whitelisted.";
        }
        displayMessage = errorMessage.search("Network Error");
        if (displayMessage!==-1) {
          errorMessage = "Network Error.";
        }
        displayMessage = errorMessage.lastIndexOf("Raw log:")
        toast.error(`${errorMessage}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
    }

    return (
        <>
          {address===undefined?<div  className='account-wrapper'>
          <div className="contract-info-wrapper">
          <div className="info-card card">
          <div style={{'margin':'0 auto'}}>Connect to Keplr Wallet to see account details.</div>
            
          </div></div></div>:
          <div  className='account-wrapper'>
          <Rewards derTokenUnit={denomConst.derTokenDenom} derTokenBalance={seJunoBalance} 
            rate={rate} tokenUnit={denomConst.tokenDenom} bondingTokenBalance={bJunoBalance} bondingTokenUnit={"bJUNO"} />

          <Unstaking  activeWindow={activeWindow} claimAmount={claimAmount} 
          pendingClaims={pendingClaims} activeWindowAmount={activeWindowAmount}
          tokenUnit={denomConst.tokenDenom} onExpire={getClaimAmount} rate={rate} />

          <Claimable claimAmount={claimAmount} secretUnit={secretUnit} claimBjunox={doBjunoxClaim} claim={doClaim}
          isCheckingAddress={isCheckingAddress} loading={loading} bondingingTokenclaimAmount={bjunoxclaimAmount} />

          <TransactionHistory transactionHistory={transactionHistory} />
          </div>}
          <ToastContainer style={{'textAlign':'left'}}/>  
          <LoadingModal open={loading}>
              {"Claiming "+ claimAmount+" " +secretUnit}
          </LoadingModal>
        </>
      );
}

export default AccountInfo;