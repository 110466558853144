import React from 'react'
import LoadingSpinner from '../../../../components/loading_spinner/LoadingSpinner';


const Claimable = (props:any) => {
    
  return (
    <div className="contract-info-wrapper">
          <h2 className="info-heading">Claimable Amount</h2>
            
          <div className="info-card card">
            {props.claimAmount===undefined? <LoadingSpinner style={{'margin':'10px auto'}} />:<>
            {null}
            {
              !props.claimAmount?<div style={{'margin':'0 auto'}}>No seJUNO rewards to be claimed.</div>:<div className="claimable-amount-wrapper" >
                <div className="claim-amount">Amount : {props.claimAmount} {props.secretUnit}</div>
                <div><button 

          disabled={ !props.claimAmount || props.isCheckingAddress || props.loading} 
          className= {'stake-btn account-btn'}
          style={{'width':'100%','margin':'0'}}
          onClick={ () => { props.claim(); } }
        >
          {props.isCheckingAddress?'Validating address...':props.loading ? 'Loading...' : `Claim Amount`}
        </button></div>
        </div>}
            </>}
            {props.bjunoxclaimAmount===null? <LoadingSpinner style={{'margin':'10px auto'}} />:<>
            {null}
            {
              !props.bjunoxclaimAmount?<div style={{'margin':'0 auto'}}>No bJUNO rewards to be claimed.</div>:<div className="claimable-amount-wrapper" >
                <div className="claim-amount">Amount : {props.bjunoxclaimAmount} {props.secretUnit}</div>
                <div><button 

          disabled={ !props.bjunoxclaimAmount || props.isCheckingAddress || props.loading} 
          className= {'stake-btn account-btn'}
          style={{'width':'100%','margin':'0'}}
          onClick={ () => { props.claimBjunox(); } }
        >
          {props.isCheckingAddress?'Validating address...':props.loading ? 'Loading...' : `Claim Amount`}
        </button></div>
        </div>}
            </>}
          
            </div>
            </div>
  )
}

export default Claimable