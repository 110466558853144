import React, { useContext } from 'react';
import LoadingSpinner from '../../components/loading_spinner/LoadingSpinner';
import { UserContext } from '../../context/user-context';
import InfoBubble from '../../utils/information/InfoBubble';
import {denomConst} from '../../utils/constants';
import './stake.css';


export function Info(){
    return(
        <div className='info-wrapper card'>
            <h2 className='info-heading max-yield'>
                {`Maximize yield by staking ${denomConst.tokenDenom} for ${denomConst.derTokenDenom}`}
            </h2>
        </div>
    )
}

export function Stats(){
    const {info, apy, isLoading} = useContext(UserContext);

    const tvl = parseFloat(info.total_staked) + parseFloat(info.juno_under_withdraw) + parseFloat(info.to_deposit);

    return(
        <div className='info-wrapper stats-wrapper card'>
            <div className='stats-child'>
            <h2 className='info-heading banner-heading'>
                TVL:
            </h2>
            <h2 className='info-heading banner-value'>
            {isLoading?<div style={{'position':'relative'}}>
                <LoadingSpinner style={{'right':'-30px', color:'white'}}/>
            </div>
            :<div className='info-tvl-value-wrapper'><span>{(tvl/1000000).toLocaleString() ?? `-`}</span> <span>{denomConst.tokenDenom}</span></div>}
            </h2>
            </div>
            
            <div className='stats-child'>
            <h2 className='info-heading banner-heading'>
                APY:
            </h2>
            <h2 className='info-heading banner-value'>
                {isLoading?<div style={{'position':'relative'}}>
                <LoadingSpinner style={{'right':'-40px', color:'white'}}/>
            </div>:<div className='info-apy-value-wrapper'>{ apy?Number(apy).toFixed(2): `-`} %</div>}
            </h2>
            {!isLoading&&<InfoBubble style={{
                'top':'-10px',
                'left':'5px'
            }} content={`Staking annual yield in ${denomConst.tokenDenom}`}/>}
            </div>
            
        </div>
    )
}
