import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons'
import WalkthroughBubble from "../../walkthrough-comp/WalkthroughBubble";
import { useRecoilValue } from 'recoil';
import { useConnectWallet } from '../../../hooks/useConnectwallet';
import { walletState } from '../../../utils/walletState';
import './Header-Socials.css'
import { UserContext } from "../../../context/user-context";


const HeaderSocials = (props:any)=>{

    const {currentWTPage} = useContext(UserContext)
    const { address, shortAddress, balance } = useRecoilValue(walletState);

    return <div className="social-header-wrapper">
        <WalkthroughBubble isOpen={currentWTPage===(address?9:6)} content='Connect with us on social media using the links here.'
       placement='right'>
       <div className={`social-header ${currentWTPage===(address?9:6)?'remove-fade-out':''}`}>

        <div className='social-header-icon '>
            <a target="_blank" href='https://discord.gg/gKjzApDstD'>
        <FontAwesomeIcon icon={faDiscord} size="lg" />
        </a>
        </div>

        <div className='social-header-icon '>
            <a target="_blank" href='https://twitter.com/StakeEasy'>
        <FontAwesomeIcon icon={faTwitter} size="lg" />
        </a>
        </div>
        <div className='social-header-icon '>
            <a target="_blank" href='https://t.me/StakeEasyProtocol'>
        <FontAwesomeIcon icon={faTelegram} size="lg" />
        </a>
        </div>
        
    </div>
    </WalkthroughBubble>
    </div>
}
export default HeaderSocials;