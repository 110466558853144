import React, { useContext, useEffect, useState } from 'react'
import Convert from '../../stake/convert'
import { ToastContainer, toast } from 'react-toastify';
import junoLogo from '../../../assets/img/juno.png'
import sejunoLogo from '../../../assets/img/sejuno.png'
import { useRecoilValue } from 'recoil'
import { walletState } from '../../../utils/walletState'
import { UserContext } from '../../../context/user-context'
import DefiInput from '../defi-input/DefiInput'
import './SwapInterface.css'
import { stakingToken } from '../../../contracts/staking_token'
import { swapPairToken } from '../../../contracts/swapPairAddress'
import LoadingModal from '../../../components/LoadingModal/LoadingModal';

const SwapInterface = (props:any) => {
    const {balance, client, address} = useRecoilValue(walletState);
    const {seJunoBalance, rate, token2For1Amount, token1For2Amount, 
        getToken1ForToken2, getToken2ForToken1, token1For2Fees, token2For1Fees, junoPriceUsd} = useContext(UserContext);

    const [swapToken, setSwapToken] = useState('juno')
    const [junoInputAmount, setJunoInputAmount] = useState('');
    const [sejunoInputAmount, setseJunoInputAmount] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [swapLoading, setSwapLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    useEffect(()=>{
      setJunoInputAmount(token2For1Amount);
  
    },[token2For1Amount])
    useEffect(()=>{
      setseJunoInputAmount(token1For2Amount);
    },[token1For2Amount])

    const handleSwitch =()=>{
      if(swapToken==='juno'){
        setSwapToken('sejuno')
    }else{
        setSwapToken('juno')
    }
    setseJunoInputAmount('');
    setJunoInputAmount('');
    setInputValue('');
    }

    const handleInputChange = async(e:any)=>{
        console.log(e.target.name);
        
        const value = (e.target.value);
        setInputValue(value)
        if(swapToken==='juno'){
            setJunoInputAmount(value);
            getToken1ForToken2(value);

          setseJunoInputAmount(token1For2Amount);
        }else{
            getToken2ForToken1(value);
          setseJunoInputAmount(value);
          setJunoInputAmount(token2For1Amount);
        }
      }

      const executeSwapToken = async()=>{
        try {

            setSwapLoading(true);

            if(swapToken==='juno'){
                setModalMessage(`Swapping ${inputValue} JUNO for ${token1For2Amount} seJUNO`)
            // setEqAmountMessage(`(~${getReceiveValue()} ${denomConst.tokenDenom})`);
            await client?.execute(
              (address as string),
              stakingToken.at,
              {
                  increase_allowance:{
                  amount:(parseFloat(inputValue)*1_000_000).toFixed(0),
                  "spender": "juno15w8wl6yzc30fwdkalp9xjqxtgyky7z5ap5wtd5vmsfe76c7j959sc09waj"
                }
            },
              {amount: [], gas: "1000000"}
            )
            await client?.execute(
              (address as string),
              swapPairToken.at,
              {
                  swap: {
                      input_token: "Token2",
                      input_amount: (parseFloat(inputValue)*1_000_000).toFixed(0),
                  min_output: (parseFloat((Number(token1For2Amount)*0.99).toLocaleString())*1_000_000).toFixed(0)||'0'
                }
            },
            {amount: [], gas: "1000000"}
            )
            toast.success(`${inputValue} amount of JUNO successfully swapped for ${token1For2Amount} seJUNO`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
        }
        else{
            setModalMessage(`Swapping ${inputValue} seJUNO for ${token2For1Amount} JUNO`)
            await client?.execute(
                (address as string),
                stakingToken.at,
                {
                    increase_allowance:{
                    amount:(parseFloat(inputValue)*1_000_000).toFixed(0),
                    "spender": "juno15w8wl6yzc30fwdkalp9xjqxtgyky7z5ap5wtd5vmsfe76c7j959sc09waj"
                  }
              },
                {amount: [], gas: "1000000"}
              )
              await client?.execute(
                (address as string),
                swapPairToken.at,
                {
                    swap: {
                        input_token: "Token1",
                        input_amount: (parseFloat(inputValue)*1_000_000).toFixed(0),
                        min_output: (parseFloat((Number(token2For1Amount)*0.99).toLocaleString())*1_000_000).toFixed(0)||'0'
                  }
              },
              {amount: [], gas: "1000000"}
              )
              toast.success(`${inputValue} amount of seJUNO successfully swapped for ${token2For1Amount} seJUNO`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        // setSwapLoading(false);
         
        //min_token1:(parseFloat(getReceiveValue())*1_000_000).toFixed(0)||'0',
    } catch (error) {
        let message = "Something wemt wrong!";
        if(error instanceof Error){
          message = error.message;
        }
        console.log(error);
        toast.error(message)
    }finally{
        setSwapLoading(false);
    }
         
}
const getReceiveValue = ():any=>{
    let value:any = '';
    if(!props.inputAmount)return value;
    if(swapToken==='sejuno'){   
      value = (Number(token2For1Amount)*0.99).toLocaleString();
    }else{
      value = (Number(props.inputAmount)/rate).toLocaleString()
    }
    return value;
    // {props.inputAmount?(props.name==='Unstake'?(Number(props.inputAmount)*rate).toLocaleString():(Number(props.inputAmount)/rate).toLocaleString()):''}
  }    

  return (
    <div className={`swap-container ${props.isOpen?'active-tab':''}`}>
    <div style={{
        'flexDirection':swapToken==='juno'?'column':'column-reverse'
    }} className='swap-input-container'>
         <DefiInput disabled={swapToken!=='juno'} onInputChange={handleInputChange} 
         value={inputValue?junoInputAmount:''} 
         tokenName='JUNO' tokenBalance={balance}  logoSrc={junoLogo} name='li-1'
         showError={swapToken==='juno'&& inputValue && Number(junoInputAmount)>Number(balance)}/>
         <div className='swap-token-toggle'>
         <span onClick={handleSwitch} className={`material-symbols-outlined switch-icon ${swapToken==='juno'?'switch-icon__rotate':''}`}>
            swap_vert
        </span>
         </div>
        <DefiInput disabled={swapToken!=='sejuno'} onInputChange={handleInputChange} 
        value={inputValue?sejunoInputAmount:''} tokenName='seJUNO'
         tokenBalance={seJunoBalance}  logoSrc={sejunoLogo} name='li-2'
         showError={swapToken==='sejuno'&&inputValue && Number(sejunoInputAmount)>Number(seJunoBalance)}/>
    </div>
    <div className='swap-rate-info-wrapper'>
    <div className='liquidity-rate-value-wrapper'>
        <div className='liquidity-rate'>
       {swapToken==='juno'&&` 1 JUNO ≈ ${junoInputAmount?(Number(sejunoInputAmount)/Number(junoInputAmount)).toLocaleString():(1/rate).toLocaleString()} seJUNO ≈ ${'$'+junoPriceUsd}`}
       {swapToken!=='juno'&&` 1 seJUNO ≈ ${sejunoInputAmount?(Number(junoInputAmount)/Number(sejunoInputAmount)).toLocaleString():(rate).toLocaleString()} JUNO ≈ ${'$'+junoPriceUsd}`}
        </div>
        <div className='liquidity-value'>
        {swapToken==='juno'&&`$${inputValue?(Number(junoInputAmount)*junoPriceUsd).toLocaleString():'0.00'}`}
        {swapToken==='sejuno'&&`$${inputValue?(Number(sejunoInputAmount)*junoPriceUsd).toLocaleString():'0.00'}`}
        </div>
      </div>
    <div className='swap-fees-wrapper'>
        <div className='swap-slippage' >
            SLIPPAGE: 1%
        </div>
        <div className='swap-fees'>
            SWAP FEES: (0.3%)
        </div>
    </div>

      </div>
    <div className='swap-footer'>
        <button onClick={executeSwapToken} disabled={ swapLoading || !junoInputAmount || !Number(junoInputAmount)} className='stake-btn swap-btn'>Swap</button>
        <span onClick={()=>props.close()} className="material-symbols-outlined liquidity-expand-icon">
          expand_more
        </span>
    </div>
    <LoadingModal open={swapLoading}>
        {modalMessage}
    </LoadingModal>
    </div>
  )
}

export default SwapInterface