import { createContext, useState } from "react";

export const BalanceToggleContext = createContext();

export const BalanceToggleContextProvider = ({ children }) => {
	const [balanceButtonToggle, setBalanceButtonToggle] = useState(false);

	console.log("BalanceButtonToggle: ", balanceButtonToggle);

	return (
		<BalanceToggleContext.Provider
			value={{ balanceButtonToggle, setBalanceButtonToggle }}
		>
			{children}
		</BalanceToggleContext.Provider>
	);
};
