import React from 'react'
import { sleep } from "../../utils/constants";
import { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { stakingToken } from "../../contracts/staking_token";
import { rewardsContract } from "../../contracts/rewards_contract";
import { stakingContract } from "../../contracts/staking_contract";
import { walletState } from "../../utils/walletState";
import Timer from '../../utils/timer/Timer';
import { coinConvert } from '@stakeordie/griptape.js';
import { useRecoilValue } from "recoil";
import { denomConst } from "../../utils/constants";
import { UserContext } from "../../context/user-context";
import LoadingSpinner from '../../components/loading_spinner/LoadingSpinner';
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import InfoBubble from "../../utils/information/InfoBubble";


  
const Withdraw = (props:any) => {
  const {claimAmount,bjunoxclaimAmount,activeWindowAmount, pendingClaims, bjunoxpendingClaims,getPendingClaims,
    getClaimAmount, secretUnit,getJunoBalance, toggleSecretUnit,getBjunoxClaimAmount,getBjunoxPendingClaims,
  seJunoBalance, bJunoBalance, totalPending,isMessageLoading,info, rate, activeWindow, isLoading, fetchWindow, isWindowFetching} = useContext(UserContext);



    const [loading, setLoading] = useState(false);
    const { client,address } = useRecoilValue(walletState);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [curPage, setCurPage] = useState(1);
    const [isCheckingAddress, setIsCheckingAddress] = useState(false);

    const [progress, setProgress] = useState<any>(0);
  const [timeLeft, setTimeLeft] = useState<Date>(new Date());
  const time = new Date();
  time.setSeconds(time.getSeconds() + 120); 

    const CustomToastWithLink = (hash:string) => (
  <div> 
    <a style={{color:"blue",textDecoration:"none"}} href={"https://www.mintscan.io/juno/txs/"+hash} target="_blank" rel="noopener noreferrer">Click to view your transaction details {hash.substr(0,5) + '...' +hash.substr(hash.length-3,3)} </a> 
  </div>
);

  useEffect(()=>{
    let sec = activeWindow.time_to_close - Math.floor(Date.now() / 1000);
    console.log("sec", sec);
    
    if (sec<0) {
      sec=0;
    }
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec+2419200); 
    setTimeLeft(time);
    setProgress(sec+2419200);  
    console.log(timeLeft, progress);
    
  },[activeWindow.time_to_close,progress]);

  const handleTimerExpire=()=>{
    const oldWindowID = activeWindow.id;
    console.log("expired");
    setProgress(0);
    let reloadTime = 15000;
    const expTimer = () =>{
      if(oldWindowID!==activeWindow.id){
        fetchWindow();
      }
    }
    setInterval(()=>{
      expTimer();
      reloadTime = reloadTime*1.1;
    }, reloadTime)

  }

    const doClaim = async () => {
      setLoading(true);
      try {
        const res=await client?.execute(
          (address as string),
          stakingContract.at,
          { 
            claim: {}
          },
          {amount: [], gas: "700000"}
        );

        setLoading(false);
        getPendingClaims();
        getClaimAmount();
        getJunoBalance();
        toast.success(`Successfully claimed ${claimAmount} ${secretUnit}.`,{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          
          toast.info(CustomToastWithLink(res?(res.transactionHash).toString():"no hash"),{
          position: "top-right",
        hideProgressBar: false,
        autoClose: 10000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      } catch (error) {
        setLoading(false);
        handleErrorMessage(error);
      }  
    }

    
    const handleErrorMessage = (error:any)=>{
      let errorMessage = "Something went wrong!";
        if (error instanceof Error) {
        errorMessage = error.message;
        }
        let displayMessage = errorMessage.lastIndexOf("Raw log:");
        if(displayMessage!==-1){
          errorMessage = errorMessage.slice(displayMessage);
        } 
        displayMessage = errorMessage.search("insufficient");
        if(displayMessage!==-1){
          errorMessage = errorMessage.slice(displayMessage,displayMessage+17).toUpperCase();
        }
        displayMessage = errorMessage.search("whitelisted");
        if (displayMessage!==-1) {
          errorMessage = "Address is not whitelisted.";
        }
        displayMessage = errorMessage.search("Network Error");
        if (displayMessage!==-1) {
          errorMessage = "Network Error.";
        }
        displayMessage = errorMessage.lastIndexOf("Raw log:")
        toast.error(`${errorMessage}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
    }
     const getRequiredTime=(claimTime:any)=>{
        const timeAsReq = new Date();
        timeAsReq.setSeconds(timeAsReq.getSeconds() + claimTime);
        return timeAsReq;
    }
    const getActiveWindowTime = ()=>{
        let sec = props.activeWindow.time_to_close - Math.floor(Date.now() / 1000);
        console.log(sec);
        
        if (sec<0) {
          sec=0;
        }
        return sec;
    }
  return (
    <div className='wrapper'>
                <div className="contract-info-wrapper">
            <h2 className="info-heading">Withdraw</h2>
          <div className="info-card card">
            {!seJunoBalance?<div style={{'margin':'0 auto'}} >No amount staked</div>:
            <div className="staked-amount-wrapper">
                <div className="staked-amount-item">
                  <div className='amt-heading'>Unstaking Amount</div> 
                  <div  className='unstaking-val'>
                    {totalPending!==undefined?
            (Number(totalPending)+parseFloat(activeWindowAmount)).toLocaleString()+" JUNO":
            (!address?<InfoBubble style={{'right':'0px','top':'-10px'}} content='Connect Keplr wallet to see this balance' />:'-')}
                    {/* <span>{seJunoBalance.toLocaleString()} </span><span>{"seJUNO"}</span></div> */}
                    </div>
                  </div>
                <div className="staked-amount-item">
                  <div className='amt-heading'>Unstaked Amount</div>
                    <div  className='unstaking-val'>
                      <span>{claimAmount+" JUNO"??(!address?<InfoBubble style={{'right':'0px','top':'-10px'}} 
            content='Connect Keplr wallet to see this balance' />:'-')} </span>
                      {/* <span>{"s"}</span> */}
                      </div>
                    </div>
            </div>}
            {/* <h2 className="info-heading">Claimable Amount</h2> */}
          <>
          {!(pendingClaims.length===0&& !activeWindowAmount) && 
          <>
          <div className="info-card-content info-card-content__head ">
              <div>AMOUNT</div>
              <div className="un-ttc-head">TIME LEFT</div>
          </div>
          <ol>
            {(activeWindowAmount!==undefined && (Number(activeWindowAmount)!==0)) && <li>
                  
                 <div className='list-numberings'>{`(${1})`}</div>
                  <div className="info-card-content unstaking-content">
                  <div className='amount-with-unit'>
                   <span>{Number(activeWindowAmount).toLocaleString()}</span> <span>{denomConst.tokenDenom}</span>
                  {/* <span>{denomConst.tokenDenom}</span> */}
                  </div>
                  <div>
                  {!progress||isLoading?`${isWindowFetching?'Fetching window...':'-'}`:
              <Timer expiryTimestamp={timeLeft}
              totalTime={345600}
               wrapperClassName="progress-bar-wrapper"
               barClassName="progress-bar"
               onExpire={handleTimerExpire}
               inlineTag={true}
               />}
                    {/* {(item.claim_time - Math.floor(Date.now() / 1000))} seconds */}
                    </div>
                  {/* <Timer expiryTimestamp={(item.claim_time - Math.floor(Date.now() / 1000))}/> */}
                </div>

                
                </li>}
          
              {pendingClaims.map((item:any,index:number) =>{
                
                const windowRemaining = activeWindow.time_to_close - (Date.now() / 1000);
                console.log("windowRemaining", windowRemaining);
                const fixtime=((activeWindow.id-item.window_id-1)*4*24*60*60)+(4*24*60*60-windowRemaining);
                console.log("fixtime",fixtime, timeLeft.getSeconds());
                const timeAsReq = getRequiredTime(28*24*60*60-fixtime);
                
                const inc = Number(activeWindowAmount)===0?1:2;
                return <li>
                  <div className='list-numberings'>{`(${index+inc})`}</div>
                  <div className="info-card-content unstaking-content">
                  <div className='amount-with-unit'>
                    <span>{coinConvert(item.juno_amount, 6, "human")}</span> <span>{denomConst.tokenDenom}</span>
                  </div>
                  <div>
                  <Timer expiryTimestamp={timeAsReq}
              totalTime={86400*32}
               wrapperClassName="progress-bar-wrapper"
               barClassName="progress-bar"
               onExpire={getClaimAmount}
               inlineTag={true}
               />
                    {/* {(item.claim_time - Math.floor(Date.now() / 1000))} seconds */}
                    </div>
                  {/* <Timer expiryTimestamp={(item.claim_time - Math.floor(Date.now() / 1000))}/> */}
                </div>
                </li>
              })}
              {props.activeWindowAmount?<li>
            <div className='list-numberings'>{`(${pendingClaims.length+1})`}</div>
                  <div className="info-card-content unstaking-content">
                  <div className='amount-with-unit'>
                  <span>{`${(parseFloat(activeWindowAmount)*parseFloat(rate)).toFixed(4)}`} </span>
                  <span>{denomConst.tokenDenom}</span>
                  </div>
                  <div>
                  <Timer expiryTimestamp={new Date().setSeconds(86400*28 + getActiveWindowTime())}
              totalTime={86400*32}
               wrapperClassName="progress-bar-wrapper"
               barClassName="progress-bar"
               onExpire={getClaimAmount}
               inlineTag={true}
               />
                    {/* {(item.claim_time - Math.floor(Date.now() / 1000))} seconds */}
                    </div>
                  {/* <Timer expiryTimestamp={(item.claim_time - Math.floor(Date.now() / 1000))}/> */}
                </div>
                </li>:null}
              </ol>
              </>
            }
            
          </>
          <div className="info-card">
            <>
            {null}
            {
              <div className="claimable-amount-wrapper" >
                
                <div className='claim-bjunox-rewards-btn'><button 

          disabled={ !claimAmount || isCheckingAddress || loading} 
          className= {'stake-btn account-btn'}
          style={{'width':'50%','margin':'0'}}
          onClick={doClaim}
        >
          {isCheckingAddress?'Validating address...':loading ? 'Loading...' : `Claim Amount`}
        </button></div>
        </div>}
            </>
          
            </div>
          </div>
            </div>
            </div>
    
  )
}

export default Withdraw
