import React from 'react'
import './hamburger.css'
const Hamburger = (props:any) => {
  return (
  <div className={`hamburger-wrapper ${props.isOpen&&'wrapper-active'}`} onClick={props.onClick}>
        <div className={`hamburger-bar top-bar ${props.isOpen&&'top-bar-active'}`}></div>
        <div className={`hamburger-bar mid-bar ${props.isOpen&&'mid-bar-active'}`}></div>
        <div className={`hamburger-bar bottom-bar ${props.isOpen&&'bottom-bar-active'}`}></div>
    </div>
  )
}

export default Hamburger