import React, { useContext, useState } from 'react'
import Modal from "react-modal"
import {useRecoilValue } from 'recoil'
import flatwhite from '../../assets/img/flatwhite.png'
import textLogo from '../../assets/img/logoText.png'
import {UserContext } from '../../context/user-context'
import {walletState} from '../../utils/walletState'

import './WelcomeModal.css'

const WelcomeModal = (props:any) => {

    const {setIsWalkingThrough} = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const {address} = useRecoilValue(walletState)
    const handleTakeTour = ()=>{
        setIsWalkingThrough(true);
        setIsModalOpen(false);
    }

  return (
    <Modal isOpen={props.isOpen && isModalOpen}
    contentLabel="My dialog"
    className="loading-modal"
    overlayClassName="myoverlay"
    closeTimeoutMS={300}>
    <div className='welcome-modal-wrapper' >
        <div className='welcome-modal-header-wrapper'>
            Welcome to
        </div>
        <div className='welcome-modal-body-wrapper'>
            <div className='welcome-modal-logo-wrapper'>
            <img alt='logo' src={flatwhite}/>
            <img alt='logo' src={textLogo}/>
            </div>
            <div className='welcome-modal-content-wrapper'>
                {/* Privacy preserving liquid staking, always! Let us quickly walk you through the App.
                 To start the tour click on "Take a tour". If you are already familiar with the App, 
                 click on "Skip to App" to start staking. */}
            </div>   
        </div>
        <div className='welcome-modal-footer-wrapper'>
            <button onClick={handleTakeTour} className='stake-btn'>Take a tour</button>
            <button onClick={()=>setIsModalOpen(false)} className='stake-btn secondary-btn'>Skip to App</button>
        </div>
    </div>
    </Modal>
  )
}

export default WelcomeModal