import {  createTheme } from '@mui/material'
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React, { useContext } from 'react'
import { UserContext } from '../../context/user-context';
import './WalkthroughBubble.css'
import { useRecoilValue } from 'recoil';
import { useConnectWallet } from '../../hooks/useConnectwallet';
import { walletState } from '../../utils/walletState';

const WalkthroughBubble = (props:any) => {

    const {isWalkingThrough,setIsWalkingThrough, setCurrentWTPage, currentWTPage} = useContext(UserContext);

    const { address, shortAddress, balance } = useRecoilValue(walletState);
    const GotIt = ()=>{

        if(currentWTPage===(address?11:8)){
          setCurrentWTPage((1));
          setIsWalkingThrough(false)

        }else{
          setCurrentWTPage((currentWTPage+1));
        }



    }
    const Skip = ()=>{

        setIsWalkingThrough(false);
        setCurrentWTPage((1));



    }
    
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));

  return (
    //   <div className='wt-bubble-wrapper'>
    //       <div className='wt-bubble'>
    //           This is where you can see your address and all
    //       </div>
    //   </div>
    <Tooltip  open={isWalkingThrough && props.isOpen} arrow title={
        <React.Fragment >
        <div className='tooltip-container'>
            <div className='tooltip-content'>
                {props.content}
            </div>
            <div className='tooltip-buttons-wrapper'>
            <button onClick={GotIt} className="next-tooltip-btn stake-btn">Got it</button>
            <button onClick={Skip } className='skip-tooltip-btn stake-btn secondary-btn'>Skip tour</button>
            </div>
            
        </div>

        </React.Fragment>
    } placement={props.placement} >

        {props.children}
        {/* </div> */}
    </Tooltip>
  )
}

export default WalkthroughBubble