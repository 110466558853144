import { Dispatch, SetStateAction, useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useRecoilValue } from "recoil";
import { stakingContract } from "../contracts/staking_contract";
import { withRouter, Link, useLocation } from 'react-router-dom';
import { rewardsContract } from "../contracts/rewards_contract";
import { stakingToken } from "../contracts/staking_token";
import { bondedToken } from "../contracts/bonded_token";
import { walletState } from "./walletState";
import {UserContext} from "../context/user-context";
import {denomConst} from '../utils/constants'
import { useConnectWallet } from '../hooks/useConnectwallet';
import LoadingModal from "../components/LoadingModal/LoadingModal";
import juno from "../assets/img/juno.png";
import seJUNO from "../assets/img/sejuno.png";
import bJUNO from '../assets/img/bjuno.png'
import axios from "axios";
import InfoBubble from "./information/InfoBubble";
import Convert from "../pages/stake/convert";
import { toUtf8 } from '@cosmjs/encoding'
import CommonButton from "./buttons/commonButton";
import InstDelToggle from "../pages/stake/instant-delayed-toggle/InstDelToggle";
import { swapPairToken } from "../contracts/swapPairAddress";
import { valueToPercent } from "@mui/base";

function StakeMenu(props: Props) {
  const {seJunoBalance, getSeJunoBalance,  getBJunoBalance,getClaimAmount,bJunoBalance,getBjunoxClaimAmount,bjunorate,
        rate, secretUnit, toggleSecretUnit, token2For1Amount, JunoBalance,getJunoBalance,getActiveWindowAmount} = useContext(UserContext)

  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [eqAmountMessage, setEqAmountMessage] = useState('');
  const [sliderVisibility, setSliderVisibility] = useState(true);
  const [isCheckingAddress, setIsCheckingAddress] = useState(false);
  const [isConvertSelected, setIsConvertSelected] = useState(false);
  const [junoUnit, setJunoUnit] = useState(denomConst.derTokenDenom);
  const [unstakeType, setUnstakeType] = useState('delayed');
  const { address, shortAddress, client, balance } = useRecoilValue(walletState);
  const tokenDenomTxn:string= denomConst.tokenDenomTxn;

  const connectWallet = useConnectWallet();

  useEffect(()=>{
    setPercent(0);
    if (props.name==='Unstake' && (seJunoBalance==='' ||seJunoBalance==='0')) {
      setSliderVisibility(false);
    } else{
      setSliderVisibility(true);
    }
    props.setInputAmount('');
  },[props.name,seJunoBalance])
  
  const doTransaction = async () => {
    console.log(props.name);
    if (props.name === `Stake ${denomConst.tokenDenom}`) {
      console.log("making depsoit");
      if(junoUnit===denomConst.derTokenDenom){
        await makeDeposit();
      }else{
           await makeDepositBjunox();
      }
      
    } else if (props.name === 'Unstake') {
      if(junoUnit===denomConst.derTokenDenom){
        await makeWithdraw();
        
      }else{
           await makeBJunoxWithdraw();
      }
      
    }
  }

  const handleErrorMessage = (error:any)=>{
    
    let errorMessage = "Something went wrong!";
      if (error instanceof Error) {
      errorMessage = error.message;
      }
      let displayMessage = errorMessage.search("insufficient");
      if(displayMessage!==-1){
        errorMessage = errorMessage.slice(displayMessage,displayMessage+17).toUpperCase();
      }
      displayMessage = errorMessage.search("whitelisted");
      if (displayMessage!==-1) {
        errorMessage = "Address is not whitelisted.";
      }
      toast.error(`${errorMessage}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
  }
  const CustomToastWithLink = (hash:string) => (
  <div> 
    <a style={{color:"blue",textDecoration:"none"}} href={"https://www.mintscan.io/juno/txs/"+hash} target="_blank" rel="noopener noreferrer">Click to view your transaction details {hash.substr(0,5) + '...' +hash.substr(hash.length-3,3)} </a> 
  </div>
);
//  let {router}:Context= this.context;
  const makeDeposit = async () => {
    var inputVal = (document.getElementById("stake-juno-input") as HTMLInputElement).value;
    setEqAmountMessage(`(~ ${(Number(props.inputAmount)/rate).toLocaleString()} ${denomConst.derTokenDenom})`);
    setLoading(true);
    try {
     const res= await client?.execute(
        (address as string),
        stakingContract.at,
        { 
          stake: {
            referral: 0
          }
        },
        {amount: [], gas: "700000"}, // custom fees
        "",
        [{amount: (parseFloat(inputVal)*1_000_000).toFixed(0), denom: tokenDenomTxn}]
      );
      console.log("transaction hash");
      console.log(res);
      setLoading(false);
      toast.success(`${inputVal} amount of ${props.placeholder} successfully staked.`,{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })

       toast.info(CustomToastWithLink(res?(res.transactionHash).toString():"no hash"),{
          position: "top-right",
        hideProgressBar: false,
        autoClose: 10000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
        
      getClaimAmount();
      getSeJunoBalance();
      getJunoBalance();
      props.setInputAmount('');
      
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleErrorMessage(error);
      return;
    }
  }

  const makeDepositBjunox = async () => {
    var inputVal = (document.getElementById("stake-juno-input") as HTMLInputElement).value;
    setEqAmountMessage(`(~ ${(Number(props.inputAmount)/bjunorate).toLocaleString()} ${junoUnit})`);
    setLoading(true);
    try {
      const res=await client?.execute(
        (address as string),
        stakingContract.at,
        { 
          stake_for_bjuno: {
            referral: 0
          } 
        },
        {amount: [], gas: "750000"}, // custom fees
        "",
        [{amount: (parseFloat(inputVal)*1_000_000).toFixed(0), denom: tokenDenomTxn}]
      );
      setLoading(false);
      toast.success(`${inputVal} amount of ${props.placeholder} successfully staked.`,{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        toast.info(CustomToastWithLink(res?(res.transactionHash).toString():"no hash"),{
          position: "top-right",
        hideProgressBar: false,
        autoClose: 10000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      getBjunoxClaimAmount();
      getBJunoBalance();
      getJunoBalance();
      props.setInputAmount('');
      
      
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleErrorMessage(error);
      return;
    }
  }

  
  const makeWithdraw = async () => {
    var inputVal = (document.getElementById("stake-juno-input") as HTMLInputElement).value;


    try {

    
        setEqAmountMessage(`(~ ${(Number(props.inputAmount)*rate).toLocaleString()} ${denomConst.tokenDenom})`)
        setLoading(true);
        const res=await client?.execute(
          (address as string),
          stakingToken.at,
          {
          send: {
            amount: (parseFloat(inputVal)*1_000_000).toFixed(0),
            contract: stakingContract.at,
            msg: "eyJ1bmJvbmQiOnt9fQ=="
          }
        },
        {
          amount: [],
          gas: "2700000",
        }, // custom fees
        "",
        []
        );
      
      
      // else{
      //   setEqAmountMessage(`(~${getReceiveValue()} ${denomConst.tokenDenom})`);
      //   setLoading(true);
      //   await client?.execute(
      //     (address as string),
      //     stakingToken.at,
      //     {
      //       increase_allowance:{
      //         amount:(parseFloat(inputVal)*1_000_000).toFixed(0),
      //         "spender": "juno15w8wl6yzc30fwdkalp9xjqxtgyky7z5ap5wtd5vmsfe76c7j959sc09waj"
      //       }
      //     },
      //     {amount: [], gas: "1000000"}
      //   )
      //   await client?.execute(
      //     (address as string),
      //     swapPairToken.at,
      //     {
      //       swap: {
      //         input_token: "Token2",
      //         input_amount: (parseFloat(inputVal)*1_000_000).toFixed(0),
      //         min_output: (parseFloat(getReceiveValue())*1_000_000).toFixed(0)||'0'
      //       }
      //     },
      //     {amount: [], gas: "1000000"}
      //   )

      // }
      //min_token1:(parseFloat(getReceiveValue())*1_000_000).toFixed(0)||'0',
      setLoading(false);
      toast.success(`${inputVal} amount of ${junoUnit} successfully initiated unstaking.`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        toast.info(CustomToastWithLink(res?(res.transactionHash).toString():"no hash"),{
          position: "top-right",
        hideProgressBar: false,
        autoClose: 10000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      getClaimAmount();
      getSeJunoBalance();
      getJunoBalance();
      getActiveWindowAmount();
      
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleErrorMessage(error);
        return;
    }
  }

   
  const makeBJunoxWithdraw = async () => {
    var inputVal = (document.getElementById("stake-juno-input") as HTMLInputElement).value;


    try {

    
        setEqAmountMessage(`(~ ${(Number(props.inputAmount)*bjunorate).toLocaleString()} ${denomConst.tokenDenom})`)
        setLoading(true);
        const res=await client?.execute(
          (address as string),
          bondedToken.at,
          {
          send: {
            amount: (parseFloat(inputVal)*1_000_000).toFixed(0),
            contract: stakingContract.at,
            msg: "eyJ1bmJvbmQiOnt9fQ=="
          }
        },
        {
          amount: [{ amount: "500000", denom: "ujuno" }],
          gas: "2700000",
        }, // custom fees
        "",
        []
        );
      
      
      // else{
      //   setEqAmountMessage(`(~${getReceiveValue()} ${denomConst.tokenDenom})`);
      //   setLoading(true);
      //   await client?.execute(
      //     (address as string),
      //     bondedToken.at,
      //     {
      //       increase_allowance:{
      //         amount:(parseFloat(inputVal)*1_000_000).toFixed(0),
      //         "spender": "juno15w8wl6yzc30fwdkalp9xjqxtgyky7z5ap5wtd5vmsfe76c7j959sc09waj"
      //       }
      //     },
      //     {amount: [], gas: "1000000"}
      //   )
      //   await client?.execute(
      //     (address as string),
      //     swapPairToken.at,
      //     {
      //       swap: {
      //         input_token: "Token2",
      //         input_amount: (parseFloat(inputVal)*1_000_000).toFixed(0),
      //         min_output: (parseFloat(getReceiveValue())*1_000_000).toFixed(0)||'0'
      //       }
      //     },
      //     {amount: [], gas: "1000000"}
      //   )

      // }

      //min_token1:(parseFloat(getReceiveValue())*1_000_000).toFixed(0)||'0',
      setLoading(false);
      toast.success(`${inputVal} amount of ${junoUnit} successfully initiated unstaking.`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        toast.info(CustomToastWithLink(res?(res.transactionHash).toString():"no hash"),{
          position: "top-right",
        hideProgressBar: false,
        autoClose: 10000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        getActiveWindowAmount();
      getBjunoxClaimAmount();
      getJunoBalance();
      getBJunoBalance();
      
    } catch (error) {
      console.log(error);
      
      setLoading(false);
      handleErrorMessage(error);
        return;
    }
  }

  const unstakeTypeHandler = (menu:string)=>{
    setUnstakeType(menu);
  }

  const handleTextInputChange=(e:any)=>{
    let curCrtBalance = Number(balance);
    if (props.name==='Unstake') {
      curCrtBalance = Number(seJunoBalance);
    }
    const val = e.target.value;
    props.setInputAmount(val);
    let percentFig = Math.floor((val*100)/curCrtBalance);
    if(percentFig>100) percentFig=100;
    setPercent(percentFig);
  }

  const handleSlideChange=(e:any)=>{
    let curCrtBalance = Number(balance);
    if (props.name==='Unstake') {
       if(junoUnit===denomConst.derTokenDenom){
        curCrtBalance = Number(seJunoBalance);
      }else{
           curCrtBalance = Number(bJunoBalance);
      }
      
    }
    let val = e.target.valueAsNumber * 0.01* Number(curCrtBalance);
        setPercent(e.target.valueAsNumber);
        props.setInputAmount(Number(val.toFixed(6)).toString())
  }

  const handleMaxClick=()=>{
    let curCrtBalance = Number(balance);
    // let val = curCrtBalance; 
    if (props.name==='Unstake'){

      if(junoUnit===denomConst.derTokenDenom){
        curCrtBalance = Number(seJunoBalance);
      }else{
           curCrtBalance = Number(bJunoBalance);
      }
      // val = curCrtBalance; 
    }
    props.setInputAmount(curCrtBalance.toString());
    setPercent(100);
  }

  const handleNodeClick=(e:any)=>{
    let curCrtBalance = Number(balance);
    if (props.name==='Unstake') {
      if(junoUnit===denomConst.derTokenDenom){
        curCrtBalance = Number(seJunoBalance);
      }else{
           curCrtBalance = Number(bJunoBalance);
      }
      
    }
    const id = Number(e.target.id);
    setPercent(id);
    let val = id * 0.01* Number(curCrtBalance);
    props.setInputAmount(Number(val.toFixed(6)).toString())
  }

  const toggleConversionUnit = (unit:string)=>{
    setJunoUnit(unit);
  }

  const handleSwitchClick = ()=>{
    if (junoUnit===denomConst.secDerTokenDenom) {
      setJunoUnit(denomConst.derTokenDenom);
    }else{
      setJunoUnit(denomConst.secDerTokenDenom)
    }
  }

  const getReceiveValue = ():any=>{
    let value:any = '';
    if(!props.inputAmount) return value;
    console.log("inpt");
    console.log(props.inputAmount);
    if(props.name==='Unstake'){
      console.log(props.inputAmount);
      console.log(rate);
      value = !(junoUnit===denomConst.secDerTokenDenom)?(Number(props.inputAmount)*rate).toFixed(5):(Number(props.inputAmount)*Number(bjunorate)).toFixed(5);
      console.log(value);
      
      // value = unstakeType==='delayed'?(Number(props.inputAmount)*rate).toLocaleString():
      // (Number(token2For1Amount)*0.99).toLocaleString();
    }else{
      value = !(junoUnit===denomConst.secDerTokenDenom)?(Number(props.inputAmount)/Number(rate)).toFixed(5):(Number(props.inputAmount)/Number(bjunorate)).toFixed(5);
    }
    return value;
    // {props.inputAmount?(props.name==='Unstake'?(Number(props.inputAmount)*rate).toLocaleString():(Number(props.inputAmount)/rate).toLocaleString()):''}
  }

  return (<>
    <div className="stake-menu">
    {props.isConvert ? <Convert/>:
     <>
      <div className="stake-menu-header">
        <div className='stake-menu-heading stake-menu__item'>{props.name} {props.name==='Unstake'&&junoUnit}</div>
        <div className='stake-rate stake-menu__item'>
          <div className="available-balance-prompt rate-prompt">
          <span>1</span> <span>{junoUnit}</span></div>
           = 
           <div className="available-balance-prompt rate-prompt"><span>{junoUnit===denomConst.derTokenDenom?Number(rate).toFixed(5):Number(bjunorate).toFixed(6)}</span> <span>JUNO</span></div>
        <InfoBubble style={{'right':'-20px', 'top':'3px'}} content="Current exchange rate"/> 
        </div>

        <div style={props.name!==`Stake ${denomConst.tokenDenom}`?{left:'0px'}:{right:'0px'}} className="stake-toggle-switch">
            <div onClick={()=>toggleConversionUnit(denomConst.derTokenDenom)}  className={`${junoUnit===denomConst.derTokenDenom?'toggle-option-active':null} toggle-option option-2`}>{denomConst.derTokenDenom}</div>
            <div onClick={()=>toggleConversionUnit(denomConst.secDerTokenDenom)} className={`${junoUnit===denomConst.secDerTokenDenom?'toggle-option-active':null} toggle-option option-1`}>{denomConst.secDerTokenDenom}</div>
        </div>
        
      </div>
      {/* {props.name==='Unstake'&&<InstDelToggle inputAmount={props.inputAmount} onChange={unstakeTypeHandler}/>} */}
      <div className="input-wrapper-container" >
      <div className="input-text-wrapper">
        <label className="input-label stake-label" htmlFor="stake-juno-input" >
          <div className="label-logo-action-wrapper">
          <img src={props.name==='Unstake'?(junoUnit===denomConst.derTokenDenom?seJUNO:bJUNO):juno} />
          {props.name===`Stake ${denomConst.tokenDenom}`?'Stake':'Unstake'}
          </div>
          <div className="available-balance-prompt">
            <p>Balance : </p>
            <span>{props.name==='Unstake'?(junoUnit===denomConst.derTokenDenom?(seJunoBalance?Number(seJunoBalance):0).toFixed(4):(bJunoBalance?Number(bJunoBalance):0).toFixed(4)||0):((JunoBalance?Number(JunoBalance):0).toFixed(4) || 0)}</span>
            <span>{props.placeholder===denomConst.tokenDenom?props.placeholder:junoUnit}</span>
            </div>

          </label>

        <div className="stake-input-wrapper">
      <input 
        className='stake-input actual-value' 
        id='stake-juno-input' 
        type='text' 
        autoComplete="off"
        placeholder={'0.00'}
        value={props.inputAmount||''}
        onChange={(e) => {handleTextInputChange(e)}} 
        />
        <div className="stake-input-unit">
        {props.placeholder===denomConst.tokenDenom?props.placeholder:junoUnit}
        </div>
        <div className="input-warning-meassages" >
        {props.name==='Unstake' && Number(props.inputAmount)>(junoUnit===denomConst.derTokenDenom?Number(seJunoBalance):Number(bJunoBalance))?'Insufficient Balance':null}
      {
      props.name===`Stake ${denomConst.tokenDenom}` ?
       props.inputAmount&&Number(props.inputAmount)<1?'At least 1 JUNO should be staked.':
       Number(props.inputAmount)>Number(balance)?<div>Insufficient Balance</div>:null:
       null}
      </div>
        </div>
      
     
      {balance&&sliderVisibility&&<button className='max-btn' onClick={handleMaxClick}>Max</button>}
      </div>
      {/* <div className="scale-balance-wrapper">
      <FontAwesomeIcon icon={faScaleBalanced} /> 
      </div> */}
      <div className="input-text-wrapper receive-value-wrapper">
      <label className="input-label receive-label" htmlFor="correspondingValue" >
      <div className="label-logo-action-wrapper">
        <img src={props.name===`Stake ${denomConst.tokenDenom}`?(junoUnit===denomConst.derTokenDenom?seJUNO:bJUNO):juno} />
        Receive
      </div></label>
      <div className="stake-input-wrapper">
      <input 
        className='stake-input receive-value' 
        id='correspondingValue' 
        type='text' 
        disabled
        placeholder={'0.00'}
        value={getReceiveValue()}
      />
      <div className="stake-input-unit">
      {props.name===`Stake ${denomConst.tokenDenom}`?junoUnit:denomConst.tokenDenom}
      </div>
      </div>
      </div>
      </div>
        
        {props.name==='Unstake'?<div style={{"marginTop":"10px",'color':'white'}}>Note: Unstaking takes 28-31 days to complete.</div>:null}
      

      {balance&&sliderVisibility&&<div className="input-slider-wrapper">
      <div className="slider-wrapper">
        <div id="0" className="slider-node node-0" onClick={(e)=>{handleNodeClick(e)} } style={{'background':`${percent>0?'rgb(198, 208, 221)':'grey'}`}} ></div>
        <div id="25" className="slider-node node-25" onClick={(e)=>{handleNodeClick(e)}} style={{'background':`${percent>25?'rgb(198, 208, 221)':'grey'}`}}></div>
        <div id="50" className="slider-node node-50" onClick={(e)=>{handleNodeClick(e)}} style={{'background':`${percent>50?'rgb(198, 208, 221)':'grey'}`}}></div>
        <div id="75" className="slider-node node-75" onClick={(e)=>{handleNodeClick(e)}} style={{'background':`${percent>75?'rgb(198, 208, 221)':'grey'}`}}></div>
        <div id="100" className="slider-node node-100" onClick={(e)=>{handleNodeClick(e)}} style={{'background':`${percent>100?'rgb(198, 208, 221)':'grey'}`}}></div>
        </div>
      <input name="percent" className='stake-input input-slider' value={percent} 
      type="range" min="0" max="100" step="1"
      onChange={(e) => {
        handleSlideChange(e)}}
      style={{'background':`linear-gradient(90deg,#f2545b ${percent}%, #a69cac ${percent}%)`}}
      />
      <div className="slider-wrapper">
        <output style={{'left':`${percent}%`}} 
        className="slider-bubble" 
        htmlFor="percent">{percent}%</output>
        </div>
        
      </div>}
      {address?<button 
        disabled={!address || !props.inputAmount || props.inputAmount==='0' || !bjunorate || !rate ||
         loading || isCheckingAddress || (props.name==='Unstake' && Number(props.inputAmount)>(junoUnit===denomConst.derTokenDenom?Number(seJunoBalance):Number(bJunoBalance))) ||
         (props.name===`Stake ${denomConst.tokenDenom}` && Number(props.inputAmount)<1) ||
        (props.name===`Stake ${denomConst.tokenDenom}` && Number(props.inputAmount)>Number(balance))}
        className='stake-btn' 
        onClick={ () => { doTransaction(); } }
      >
        {isCheckingAddress?'Validating address...':loading ? 'Loading...' : props.name==='Unstake'?('Unstake '+junoUnit):props.name}
      </button>:
      <CommonButton
        walkThroughFlag={false}
        isBubble={false}
        name="Connect Wallet" 
        alt_name={shortAddress as string} 
        onClickHandler={()=>connectWallet()} 
        disabled={address !== undefined} 
      
      />
      }
      </>}
    </div>
    {/* <ToastContainer style={{'textAlign':'left'}}/> */}
    <LoadingModal open={loading} >

          {props.isConvert?"Converting":props.name.split(" ")[0].slice(0,-1)+"ing"}{" "+props.inputAmount+" "}
          {!props.isConvert && (props.name==="Unstake"?junoUnit===denomConst.derTokenDenom?'seJUNO':'bJUNO':'JUNO')}
          <br></br>
          {eqAmountMessage}        
    </LoadingModal>
    
    </>
  );
}

type Props = {
  name: string; 
  placeholder: string; 
  inputAmount: string | null | undefined; 
  isConvert: boolean;
  setInputAmount: Dispatch<SetStateAction<string | null | undefined>>;
}

export default StakeMenu;
