import React, { useContext, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import './Liquidity.css'
import junoLogo from '../../../assets/img/juno.png'
import sejunoLogo from '../../../assets/img/sejuno.png'
import { useRecoilValue } from 'recoil'
import { walletState } from '../../../utils/walletState'
import { UserContext } from '../../../context/user-context'
import DefiInput from '../defi-input/DefiInput'
import { stakingToken } from '../../../contracts/staking_token'
import { swapPairToken } from '../../../contracts/swapPairAddress'
import LoadingModal from '../../../components/LoadingModal/LoadingModal';

const LiquidityInterface = (props:any) => {
  const [liqHeight, setLiqHeight] = useState('0');
  const [junoInputAmount, setJunoInputAmount] = useState('');
  const [sejunoInputAmount, setseJunoInputAmount] = useState('');
  const [liqLoading, setLiqLoading] = useState(false);
  const [liqLoadingMessage, setLiqLoadingMessage] = useState('');
  const {address, balance,client} = useRecoilValue(walletState);
  const {seJunoBalance, rate, poolRatio, junoPriceUsd} = useContext(UserContext);

  const handleInputChange = (e:any)=>{
    console.log(e.target.name);
    
    const value = (e.target.value);
    if(e.target.name==='juno'){
      setJunoInputAmount(value);
      setseJunoInputAmount((Number(value)*poolRatio).toLocaleString().replaceAll(',',''));
    }else{
      setseJunoInputAmount(value);
      setJunoInputAmount((Number(value)/poolRatio).toLocaleString().replaceAll(',',''));
    }
  }

  const addLiquidity = async()=>{
    setLiqLoadingMessage(`Adding ${junoInputAmount} JUNO to liquidity.`)
    setLiqLoading(true);
    try {
      
      await client?.execute(
        (address as string),
      stakingToken.at,
      {
        increase_allowance:{
          amount:(parseFloat(junoInputAmount)*1_000_000).toFixed(0),
          "spender": "juno15w8wl6yzc30fwdkalp9xjqxtgyky7z5ap5wtd5vmsfe76c7j959sc09waj"
        }
      },
      {amount: [], gas: "1000000"}
      )
      await client?.execute(
        (address as string),
        swapPairToken.at,
        {
          add_liquidity: {
            token1_amount: (parseFloat(junoInputAmount)*1_000_000).toFixed(0),
            max_token2: (parseFloat(sejunoInputAmount)*1_000_000).toFixed(0)||'0',
            min_liquidity:"0"
          }
        },
        {amount: [], gas: "1000000"}
        )
        toast.success(`${junoInputAmount} amount of ${'JUNO'} liquidity added.`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      } catch (error) {
        let message = "Something wemt wrong!";
        if(error instanceof Error){
          message = error.message;
        }
        console.log(error);
        toast.error(message)
      }finally{
        setLiqLoading(false);
      }
  }

  return (
    <div className={`liquidity-outer-wrapper ${props.isOpen?'active-tab':''}`}>
      <div className='liquidity-inputs-wrapper'>
        <DefiInput showError={Number(junoInputAmount)>Number(balance)} name='juno' 
        onInputChange={handleInputChange} value={junoInputAmount} tokenName='JUNO' 
        tokenBalance={balance}  logoSrc={junoLogo}/>
        <DefiInput showError={Number(sejunoInputAmount)>Number(seJunoBalance)} name='sejuno' 
        onInputChange={handleInputChange} value={sejunoInputAmount} tokenName='seJUNO' 
        tokenBalance={seJunoBalance}  logoSrc={sejunoLogo}/>
        <div className='liquidity-rate-value-wrapper'>
        <div className='liquidity-rate'>
        1 JUNO ≈ {junoInputAmount?(Number(sejunoInputAmount)/Number(junoInputAmount)).toFixed(4):
        (1/rate).toLocaleString()} seJUNO ≈ ${junoPriceUsd}
        </div>
        <div className='liquidity-value'>
        ${junoInputAmount?(Number(junoInputAmount)*junoPriceUsd*2).toLocaleString():'0.00'}
        </div>
      </div>
      </div>
      <div className='liquidity-footer'>
        <div className='liquidity-buttons-wrapper'>
          <button disabled={ liqLoading || !junoInputAmount || !Number(junoInputAmount)||
          (Number(junoInputAmount)>Number(balance) || Number(sejunoInputAmount)>Number(seJunoBalance))
          }
           onClick={addLiquidity} className='stake-btn liquidity-btn'>{liqLoading?'Loading':'Add Liquidity'}</button>
        </div>
        <span onClick={()=>props.close()} className="material-symbols-outlined liquidity-expand-icon">
          expand_more
        </span>
      </div>
      <LoadingModal open={liqLoading} >
          {liqLoadingMessage}        
    </LoadingModal>
    </div>
  )
}

export default LiquidityInterface