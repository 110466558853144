import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useRecoilValue } from "recoil";
import axios from 'axios';

import { stakingToken } from "../contracts/staking_token";
import { bondedToken } from "../contracts/bonded_token";
import { chainInfo } from '../utils/chainInfo';
import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { useSetRecoilState } from 'recoil';
import { walletState } from '../utils/walletState';
import { stakingContract } from "../contracts/staking_contract";
import { rewardsContract } from "../contracts/rewards_contract";
import { contractAddress } from "../contracts/contactAddress";
import { coinConvert } from "@stakeordie/griptape.js";
import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { Encoding } from "@iov/encoding";
import { API_URL, sleep, denomConst, QUERY_API_URL } from "../utils/constants";
import { swapPairToken } from "../contracts/swapPairAddress";

const CoinGecko = require('coingecko-api');

export const useData = () => {
  let queryClient: any;
  const { address, client, balance } = useRecoilValue(walletState);

  const [seJunoBalance, setSeJunoBalance] = useState("");
  const [JunoBalance, setJunoBalance] = useState("");
  const [bJunoBalance, setBJunoBalance] = useState("");
  const [isMessageLoading, setMessageLoading] = useState(false);
  const [claimAmount, setClaimAmount] = useState<any>();
  const [bjunoxclaimAmount, setBjunoxClaimAmount] = useState<any>();
  const [pendingClaims, setPendingClaims] = useState([]);
  const [bjunoxpendingClaims, setBjunoxPendingClaims] = useState([]);
  const [totalPending, setTotalPending] = useState<any>();
  const [bjunoxtotalPending, setBjunoxTotalPending] = useState<any>();
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [rate, setRate] = useState("");
  const [bjunorate, setBjunoRate] = useState("");
  const [info, setInfo] = useState<any>({});
  const [apy, setApy] = useState<any>("");
  const [activeWindow, setActiveWindow] = useState<any>({});
  const [isLoading, setIsLoading] = useState<any>(true);
  const [secretUnit, setSecretUnit] = useState(denomConst.tokenDenom);
  const [isWindowFetching, setIsWindowFetching] = useState(false);
  const [activeWindowAmount, setActiveWindowAmount] = useState<any>(0);
  const [isWalkingThrough, setIsWalkingThrough] = useState<Boolean>(false);
  const [isMobileUser, setIsMobileUser] = useState<Boolean>(false);
  const [currentWTPage, setCurrentWTPage] = useState<number>(1);
  const [token2For1Amount, setToken2For1Amount] = useState('');
  const [token1For2Amount, setToken1For2Amount] = useState('');
  const [token2For1Fees, setToken2For1Fees] = useState('');
  const [token1For2Fees, setToken1For2Fees] = useState('');
  const [poolTvl, setPoolTvl] = useState('-');
  const [poolRatio, setPoolRatio] = useState(1)
  const [junoPriceUsd, setJunoPriceUsd] = useState(0);

  useEffect(() => {
    getRate();
    getBjunoRate();
    getTokenInfo();

  }, [rate]);


  useEffect(() => {
    getSeJunoBalance();
    getClaimAmount();
    getBjunoxClaimAmount();
    getBjunoxPendingClaims();
    getBJunoBalance();
    getJunoBalance();
    // getActiveWindowAmount();
    // getPendingClaims();
  }, [balance, address,])

  useEffect(() => {

    getActiveWindowAmount();
    getPendingClaims();
  }, [balance, address, rate, bjunorate])

  useEffect(() => {
    fetchJunoPriceUsd();
    const fetchData = async () => {
      queryClient = await CosmWasmClient.connect(API_URL);
      await fetchInfo();
      await fetchApy();
      await fetchWindow();

    }
    fetchData();
  }, []);

  const toggleSecretUnit = (unit: string) => {
    setSecretUnit(unit);
  }

  const resetBalances = () => {
    setSeJunoBalance("");
    setBJunoBalance("");
    setClaimAmount(undefined);
    setBjunoxClaimAmount(undefined);
    setActiveWindowAmount(0);
    setTotalPending(undefined)
  }

  const getRate = async () => {
    const queryClient = await CosmWasmClient.connect(QUERY_API_URL);
    let response;
    try {
      sleep(1);
      response = await queryClient.queryContractSmart(stakingContract.at, { sejuno_exchange_rate: {} });
      console.log("rate", response);
    } catch (error) {
      console.log(error);
      //handleErrorMessage(error);
      return;
    }

    let exch_rate = response.sejuno_exchange_rate.rate;
    if (exch_rate.length > 10) {
      exch_rate = exch_rate.slice(0, 10);
    }
    setRate(exch_rate);
  }

  const getBjunoRate = async () => {
    const queryClient = await CosmWasmClient.connect(QUERY_API_URL);
    let response;
    try {
      sleep(1);
      response = await queryClient.queryContractSmart(stakingContract.at, { bjuno_exchange_rate: {} });
      console.log("bjunorate", response);
    } catch (error) {
      console.log(error);
      //handleErrorMessage(error);
      return;
    }

    let exch_rate = response.bjuno_exchange_rate.rate;
    if (exch_rate.length > 10) {
      exch_rate = exch_rate.slice(0, 10);
    }
    setBjunoRate(exch_rate);
  }

  async function fetchInfo() {
    const infoRes = await queryClient?.queryContractSmart(
      stakingContract.at,
      {
        info: {}
      }
    );
    console.log(infoRes);
    setInfo(infoRes.info);
  }

  async function fetchApy() {
    try {
      setApy(50.56)
    } catch (error) {
      console.log(error);
      return;

    }
    setIsLoading(false);
  }

  async function fetchWindow() {
    setIsWindowFetching(true);
    const windowRes = await queryClient?.queryContractSmart(
      stakingContract.at,
      {
        window: {}
      }
    );
    console.log(windowRes);
    setActiveWindow(windowRes.window);
    setIsWindowFetching(false);
  }

  async function getSeJunoBalance() {
    setMessageLoading(true);
    try {
      sleep(1);
      const balanceRes = await client?.queryContractSmart(
        stakingToken.at,
        {
          balance: {
            address: address,
          }
        }
      );

      const amount = coinConvert(balanceRes.balance, 6, "human");
      console.log("sejuno amount", balanceRes);

      setSeJunoBalance(amount);
      setMessageLoading(false);
    } catch (error) {
      setMessageLoading(false);
      return;
    }
  }

  async function getJunoBalance() {
    setMessageLoading(true);
    try {

      await sleep(1);
      await (window as any).keplr?.experimentalSuggestChain(chainInfo);
      const chainId = 'juno-1';
      await (window as any).keplr?.enable(chainId);
      if ((window as any).keplr === undefined) return;
      const offlineSigner = await (window as any).getOfflineSignerAuto(chainId);

      const accounts = await offlineSigner.getAccounts();

      const wasmChainClient = await SigningCosmWasmClient.connectWithSigner(
        API_URL,
        offlineSigner
      );

      const accountBalance = await wasmChainClient.getBalance(accounts[0].address, "ujuno");
      // const amount = coinConvert(balanceRes.balance, 6, "human");
      // console.log("sejuno amount", balanceRes);


      setJunoBalance(coinConvert(accountBalance.amount as string, 6, 'human').toString());
      // setMessageLoading(false);
    } catch (error) {
      setMessageLoading(false);
      return;
    }
  }

  async function getBJunoBalance() {
    setMessageLoading(true);
    try {
      sleep(1);
      const balanceRes = await client?.queryContractSmart(
        bondedToken.at,
        {
          balance: {
            address: address,
          }
        }
      );

      const amount = coinConvert(balanceRes.balance, 6, "human");
      console.log("bjuno amount", balanceRes);

      setBJunoBalance(amount);
      setMessageLoading(false);
    } catch (error) {
      setMessageLoading(false);
      return;
    }
  }

  const getClaimAmount = async () => {
    while (address === undefined) {
      await sleep(1);
    }
    try {
      sleep(1);
      const response = await client?.queryContractSmart(
        stakingContract.at,
        { user_claimable: { address: address } }
      );
      console.log("rewards");
      console.log(response);
      let amount = response.claimable.claimable_amount;
      const convertedAmount = coinConvert(amount, 6, "human");
      setClaimAmount(parseFloat(convertedAmount));
    } catch (error) {
      return;
    }

  }
  const getBjunoxClaimAmount = async () => {
    while (address === undefined) {
      await sleep(1);
    }
    try {
      sleep(1);
      const response = await client?.queryContractSmart(
        rewardsContract.at,
        { accrued_rewards: { address: address } }
      );
      console.log("bjunox rewards");
      console.log(response);
      let amount = response.rewards;
      const convertedAmount = coinConvert(amount, 6, "human");
      setBjunoxClaimAmount(parseFloat(convertedAmount));
      console.log(bjunoxclaimAmount);
    } catch (error) {
      console.log("error");
      console.log(error);
      return;
    }

  }

  const getActiveWindowAmount = async () => {
    while (address === undefined) await sleep(1);
    const queryClient = await CosmWasmClient.connect(QUERY_API_URL);
    try {
      sleep(1);
      const response = await queryClient?.queryContractSmart(
        stakingContract.at,
        { active_unbonding: { address: address } }
      );
      console.log("active unbonding", response);
      let bjunoamount = response.active_undelegation.bjuno_amount;
      let sejunoamount = response.active_undelegation.sejuno_amount;
      const convertedAmount = ((Number(coinConvert(sejunoamount, 6, "human")) * Number(rate)) + (Number(coinConvert(bjunoamount, 6, "human")) * Number(bjunorate))).toString();
      setActiveWindowAmount(parseFloat(convertedAmount));
    } catch (error) {
      console.log(error);
      return;
    }

  }

  const getPendingClaims = async () => {
    while (address === undefined) {
      await sleep(1);
    }
    const queryClient = await CosmWasmClient.connect(QUERY_API_URL);
    try {
      sleep(1);
      const response = await queryClient?.queryContractSmart(
        stakingContract.at,
        { undelegations: { address: address } }
      );
      console.log("pending", response);
      let undelegations = response.pending_claims.pending;
      let totalUn = 0;
      for (const undel of undelegations) {
        totalUn += parseFloat(coinConvert(undel.juno_amount, 6, "human"));
      }
      console.log("totalUn", totalUn);
      setPendingClaims(undelegations);
      setTotalPending(totalUn);
    } catch (error) {
      return;
    }

  }
  const getBjunoxPendingClaims = async () => {
    while (address === undefined) {
      await sleep(1);
    }
    const queryClient = await CosmWasmClient.connect(QUERY_API_URL);
    try {
      sleep(1);
      const response = await queryClient?.queryContractSmart(
        rewardsContract.at,
        { undelegations: { address: address } }
      );
      console.log("pending", response);
      let undelegations = response.pending_claims.pending;
      let totalUn = 0;
      for (const undel of undelegations) {
        totalUn += parseFloat(coinConvert(undel.juno_amount, 6, "human"));
      }
      console.log("totalUn", totalUn);
      setBjunoxPendingClaims(undelegations);
      setBjunoxTotalPending(totalUn);
    } catch (error) {
      return;
    }

  }

  const getToken2ForToken1 = async (amount: string) => {
    console.log(amount);
    const queryClient = await CosmWasmClient.connect(API_URL);
    if (!amount) return;
    const swapInfo = await queryClient?.queryContractSmart(
      swapPairToken.at,
      {
        token2_for_token1_price: {
          "token2_amount": `${(Number(amount) * 1000000).toFixed(0) || 0}`
        }
      }
    );
    console.log(swapInfo);
    const token1_amount = Number(swapInfo.token1_amount) / 1000000;
    let fees = (((1 - Number(token1_amount) / (Number(amount) * Number(rate))) * 100)).toLocaleString();
    if (swapInfo.token1_amount === '0') fees = "0.3";
    setToken2For1Fees(fees);
    setToken2For1Amount(token1_amount.toLocaleString());
  }

  const getToken1ForToken2 = async (amount: string) => {
    console.log(amount);
    const queryClient = await CosmWasmClient.connect(API_URL);
    if (!amount) return;
    const swapInfo = await queryClient?.queryContractSmart(
      swapPairToken.at,
      {
        token1_for_token2_price: {
          "token1_amount": `${(Number(amount) * 1000000).toFixed(0) || 0}`
        }
      }
    );
    console.log(swapInfo);
    const token2_amount = Number(swapInfo.token2_amount) / 1000000;
    let fees = (((1 - Number(token2_amount) / (Number(amount) / Number(rate))) * 100)).toLocaleString();
    if (swapInfo.token2_amount === '0') fees = "0.3";
    setToken1For2Fees(fees);
    setToken1For2Amount(token2_amount.toLocaleString());
  }

  const getTokenInfo = async () => {
    const queryClient = await CosmWasmClient.connect(API_URL);
    try {
      const tokenInfo = await queryClient?.queryContractSmart(
        swapPairToken.at,
        {
          info: {}
        }
      );
      console.log(tokenInfo);

      let tvl;
      if (tokenInfo) {
        const ratio = Number(tokenInfo.token2_reserve) / Number(tokenInfo.token1_reserve);
        console.log(ratio);
        tvl = (Number(tokenInfo.token1_reserve) / 1000000 * junoPriceUsd) * 2;
        if (rate) setPoolTvl(tvl.toLocaleString())
        setPoolRatio(ratio);
      }
    } catch (error) {
      console.log(error);

    }
  }

  const fetchJunoPriceUsd = async () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobileUser(true);
    }
    const CoinGeckoClient = new CoinGecko();
    let data = await CoinGeckoClient.simple.price({
      ids: ['juno-network'],
      vs_currencies: ['usd'],
    });
    if (data) {
      const price = data.data["juno-network"].usd;
      console.log(price);
      setJunoPriceUsd(price);
    }
  }


  return {
    isLoggingIn, setIsLoggingIn, seJunoBalance, getSeJunoBalance, getBJunoBalance,
    isMessageLoading, getClaimAmount, claimAmount, activeWindowAmount, getActiveWindowAmount,
    getPendingClaims, getBjunoxPendingClaims, pendingClaims, bjunoxpendingClaims, totalPending, bjunoxtotalPending, bJunoBalance, getBjunoxClaimAmount, bjunoxclaimAmount,
    info, activeWindow, fetchWindow, isLoading, apy, rate, bjunorate, getJunoBalance, JunoBalance,
    secretUnit, toggleSecretUnit, isWindowFetching, resetBalances,
    isWalkingThrough, setIsWalkingThrough, currentWTPage, setCurrentWTPage,
    token2For1Amount, getToken2ForToken1, token1For2Amount, getToken1ForToken2,
    token1For2Fees, token2For1Fees, poolTvl, poolRatio, junoPriceUsd, isMobileUser
  };
}
