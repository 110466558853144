import { useContext } from "react";
import { ToastContainer } from "react-toastify";
import { useRecoilValue } from "recoil";
import bjuno from "../../assets/img/bjuno.png";
import juno from "../../assets/img/juno.png";
import sejuno from "../../assets/img/sejuno.png";
import WalkthroughBubble from "../../components/walkthrough-comp/WalkthroughBubble";
import { UserContext } from "../../context/user-context";
import { denomConst } from "../../utils/constants";
import InfoBubble from "../../utils/information/InfoBubble";
import { walletState } from "../../utils/walletState";
import "./balance.css";

function Balance() {
	const {
		seJunoBalance,
		bJunoBalance,
		isMessageLoading,
		bjunorate,
		claimAmount,
		bjunoxclaimAmount,
		totalPending,
		activeWindowAmount,
		rate,
		currentWTPage,
		JunoBalance,
		setIsWalkingThrough,
	} = useContext(UserContext);
	const { address, balance } = useRecoilValue(walletState);
	const handleTakeTour = () => {
		setIsWalkingThrough(true);
		// setIsModalOpen(false);
	};
	return (
		<WalkthroughBubble
			isOpen={currentWTPage === (address ? 11 : 8)}
			placement='left'
			content='Enquire your Balances here. This section shows all of the different credits in your account.'
		>
			<div
				className={`balance-container card ${
					currentWTPage === (address ? 11 : 8) ? "remove-fade-out" : ""
				}`}
			>
				<div className='balance-heading'>
					Balances
					<InfoBubble edge={true} content='Wallet Balances' />
				</div>
				<div className='balance-logo-wrapper'>
					<img src={sejuno} alt='sejuno' />
					<img src={bjuno} alt='bjuno' />
					<img src={juno} alt='juno' />
				</div>
				<div className='balance-wrapper'>
					<div className='logo-balance'>
						<div className='stk-balance-wrapper dull'>
							<div className='stk-balance'>
								{seJunoBalance ? (
									seJunoBalance
								) : !address ? (
									<InfoBubble
										edge={true}
										style={{ right: "0px", top: "-10px" }}
										content={`Connect Keplr wallet to see ${"seJUNO"} balance`}
									/>
								) : (
									"-"
								)}
							</div>

							<div className='token-name'>
								{"seJUNO"}

								{seJunoBalance && (
									<InfoBubble
										conversion={true}
										style={{ right: "-25px", top: "-20px" }}
										content={`${Number(seJunoBalance * rate).toFixed(4)} JUNO`}
									/>
								)}
							</div>
						</div>
					</div>
					<div className='logo-balance'>
						<div className='stk-balance-wrapper dull'>
							<div className='stk-balance'>
								{bJunoBalance ? (
									bJunoBalance
								) : !address ? (
									<InfoBubble
										edge={true}
										style={{ right: "0px", top: "-10px" }}
										content={`Connect Keplr wallet to see ${"bJUNO"} balance`}
									/>
								) : (
									"-"
								)}
							</div>

							<div className='token-name'>
								{"bJUNO"}
								{bJunoBalance && (
									<InfoBubble
										conversion={true}
										style={{ right: "-27px", top: "-20px" }}
										content={`${Number(bJunoBalance * bjunorate).toFixed(
											4
										)} JUNO`}
									/>
								)}
							</div>
						</div>
					</div>
					<div className='logo-balance'>
						<div className='stk-balance-wrapper dull'>
							<div className='stk-balance'>
								{balance ? (
									JunoBalance
								) : !address ? (
									<InfoBubble
										edge={true}
										style={{ right: "0px", top: "-10px" }}
										content={`Connect Keplr wallet to see ${denomConst.tokenDenom} balance`}
									/>
								) : (
									"-"
								)}
							</div>
							<div className='token-name'>{denomConst.tokenDenom}</div>
						</div>
					</div>
					<div className='logo-balance logo-balance__ready'>
						<div className='stk-balance-wrapper dull'>
							<div className='stk-balance'>
								{totalPending !== undefined ? (
									(
										Number(totalPending) +
										Number(activeWindowAmount)
									).toLocaleString()
								) : !address ? (
									<InfoBubble
										edge={true}
										style={{ right: "0px", top: "-10px" }}
										content='Connect Keplr wallet to see this balance'
									/>
								) : (
									"-"
								)}
							</div>
							<div className='token-name'>
								Unstaking {denomConst.tokenDenom}
							</div>
						</div>
					</div>
					<div className='logo-balance logo-balance__ready'>
						<div className='stk-balance-wrapper dull'>
							<div className='stk-balance'>
								{claimAmount ??
									(!address ? (
										<InfoBubble
											edge={true}
											style={{ right: "0px", top: "-10px" }}
											content='Connect Keplr wallet to see this balance'
										/>
									) : (
										"-"
									))}
							</div>
							<div className='token-name'>Unstaked {denomConst.tokenDenom}</div>
						</div>
					</div>
					<div className='logo-balance logo-balance__ready'>
						<div className='stk-balance-wrapper dull'>
							<div className='stk-balance'>
								{bjunoxclaimAmount ??
									(!address ? (
										<InfoBubble
											edge={true}
											style={{ right: "0px", top: "-10px" }}
											content='Connect Keplr wallet to see this balance'
										/>
									) : (
										"-"
									))}
							</div>
							<div className='token-name'>bJUNO rewards</div>
						</div>
					</div>
				</div>
				<ToastContainer style={{ textAlign: "left" }} />
				<button onClick={handleTakeTour} className='tour-btn'>
					Take a tour
				</button>
			</div>
		</WalkthroughBubble>
	);
}

export default Balance;
