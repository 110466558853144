export function sleep(seconds: number) {
  return new Promise(resolve => setTimeout(resolve, seconds*1000));
}
// export const API_URL = window.location.origin.toString()==='http://localhost:3000'?
// 'https://rpc.juno.strange.love/':
// `${window.location.origin.toString()}/.netlify/functions/cors`;

export const API_URL = 'https://juno-rpc.reece.sh';
export const QUERY_API_URL = 'https://juno-rpc.reece.sh';

export const VALIDATOR_MAP = [
  {
    address:'junovaloper1dru5985k4n5q369rxeqfdsjl8ezutch8mc6nx9',
    name:'Highlander | ChainTools'
  },
  {
    address:'junovaloper196ax4vc0lwpxndu9dyhvca7jhxp70rmcqcnylw',
    name:'SG-1'
  },
  {
    address:'junovaloper194v8uwee2fvs2s8fa5k7j03ktwc87h5ym39jfv',
    name:'Disperze'
  },
  {
    address:'junovaloper10wxn2lv29yqnw2uf4jf439kwy5ef00qdelfp7r',
    name:'kingnodes 👑'
  },
  {
    address:'junovaloper1wd02ktcvpananlvd9u6jm3x3ap3vmw59jv9vez',
    name:'Lavender.Five Nodes 🐝'
  },
  {
    address:'junovaloper193xl2tqh2tjkld2zv49ku5s44ee4qmgr65jcep',
    name:'WhisperNOde'
  },
  {
    address:'junovaloper1xwazl8ftks4gn00y5x3c47auquc62ssuvynw64',
    name:'jabbey'
  },
  {
    address:'junovaloper17skjxhtt54prnpxcs7a5rv9znlldpe5k3x99gp',
    name:'DEUS LABS'
  },
  {
    address:'junovaloper10y7ucn6jhjtakwchgpw32y0tgaku6yn255z7gm',
    name:'Golden Ratio Staking'
  },
  {
    address:'junovaloper1gp957czryfgyvxwn3tfnyy2f0t9g2p4pvzc6k3',
    name:'polkachu.com'
  },
  {
    address:'junovaloper1zxx8j75ngm8m38v9l5wreaavwnsuun7gcq5cu8',
    name:'CommunityStaking'
  },
  {
    address:'junovaloper1hx9yj7qgnp8zhkrqfanvz74mcsg9d8eyskvsxg',
    name:'Stakely.io'
  },
  {
    address:'junovaloper1gr56uqre7dsqjkknssne7rc9wunsk08gmh3c7h',
    name:'Don Cryptonium'
  },
  {
    address:'junovaloper16s96n9k9zztdgjy8q4qcxp4hn7ww98qk0du5jq',
    name:'Oni ⛩️'
  },
]

export const denomConst = {
    tokenDenom:'JUNO',
    derTokenDenom:'seJUNO',
    secDerTokenDenom:'bJUNO',
    tokenDenomTxn:'ujuno',
    derTokenDenomTxn:'sejuno',
    sDerTokenDenomTxn:'bjuno'
}