import React, { useContext, useState } from 'react';
import './DexElement.css';
import junoSwapLogo from '../../../assets/img/JunoSwapLogo.svg';
import junoSwapTextLogo from '../../../assets/img/junoswap-logo.png'
import junoLogo from '../../../assets/img/juno.png'
import seJunoLogo from '../../../assets/img/sejuno.png'
import { denomConst } from '../../../utils/constants';
import Convert from '../../stake/convert';
import LiquidityInterface from '../liquidity-interface/LiquidityInterface';
import SwapInterface from '../swap-interface/SwapInterface';
import { UserContext } from '../../../context/user-context';

const DexElement = () => {
  const [isLiqOpen, setIsLiqOpen] = useState(false);
  const [isSwapOpen, setIsSwapOpen] = useState(false);
  const {poolTvl} = useContext(UserContext);

  const handleButtonClicks = (e:any)=>{
    console.log(e.target.id);
    
    if(isLiqOpen || e.target.id==='swap'){
      setIsLiqOpen(false);
      setIsSwapOpen(true);
    }else{
      setIsLiqOpen(true);
      setIsSwapOpen(false);
    }
  }

  return (<div className='dex-element-outer-wrapper'>
    <div  className='dex-element-wrapper'>

        <div className='dex-pool-information-wrapper'>
          <div className='dex-exchange-tokens-wrapper'>
            <div className='dex-token-images-wrapper'>
              <div className='dex-juno-logo'><img src={junoLogo} /></div>
              <div className='dex-sejuno-logo'><img src={seJunoLogo} /></div>
            {/* <img src={seJunoLogo}/> */}
            </div>
          <div className='dex-item dex-item-tokens'>
          {denomConst.tokenDenom}/{denomConst.derTokenDenom}
          </div>
          
          </div>
        
          <div className='dex-apy-tvl-wrapper'>

          <div className='dex-item dex-item-apy'>
          APY: 0%
          </div>
          <div className='dex-item dex-item-tvl'>
          TVL: ${poolTvl}
          </div>
          </div>
          
        </div>
        <div className='dex-element-image-wrapper'>
            <span>on</span>
            <div className='dex-element-image'>
            <img src={junoSwapTextLogo} />
            
            </div>
        </div>
          <div className='dex-element-buttons-wrapper'>
            <button disabled={isLiqOpen || poolTvl==='-'} onClick={handleButtonClicks} className='stake-btn dex-element-button'>Add Liquidity</button>
            <button id='swap' disabled={isSwapOpen || poolTvl==='-'} onClick={handleButtonClicks} className='stake-btn dex-element-button'>Swap</button>
          </div>
    </div>
    <LiquidityInterface isOpen={isLiqOpen} close={()=>setIsLiqOpen(false)} />
    <SwapInterface isOpen={isSwapOpen} close={()=>setIsSwapOpen(false)}/>
    </div>
  )
}

export default DexElement