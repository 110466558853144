import React from 'react'
import './Defi.css'
import DexElement from './DexElement/DexElement'

const Defi = () => {
  return (
    <div className='wrapper'>
        <div className='defi-inner-wrapper'>
            <div className='defi-header-wrapper'>
                <div className='defi-header-item defi-header-item__active'>
                    Liquidity Pool
                </div>
            </div>
            <div className='defi-body-wrapper'>
                <DexElement/>
            </div>
        </div>
    </div>
  )
}

export default Defi