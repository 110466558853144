import { faBug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { BalanceToggleContext } from "../../context/balanceToggleContext";
import { UserContext } from "../../context/user-context";
import Balance from "../../pages/stake/balance";
import { walletState } from "../../utils/walletState";
import WalkthroughBubble from "../walkthrough-comp/WalkthroughBubble";
import "./RightPane.css";
const RightPane = (props: any) => {
	const { currentWTPage } = useContext(UserContext);
	const { address, shortAddress, balance } = useRecoilValue(walletState);

	const { balanceButtonToggle } = useContext(BalanceToggleContext);

	let toggleClass = "";

	if (
		(window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth <= 768) &&
		balanceButtonToggle
	) {
		toggleClass = " balanceToggleClass";
	} else {
		toggleClass = " ";
	}
	return (
		<div className={`right-pane-container ${toggleClass}`}>
			<div className='report-issue-wrapper'>
				<div className='issue-heading'>
					<FontAwesomeIcon icon={faBug} size='2x' />
					<h1>Had an issue?</h1>
				</div>
				<div className='issue-content'>
					<p>
						This website is still in development. Support us by reporting any
						issue to us via our Discord channel.
					</p>
				</div>
				<div className='issue-button'>
					<a target='_blank' href='https://discord.gg/gKjzApDstD'>
						<WalkthroughBubble
							isOpen={currentWTPage === (address ? 10 : 7)}
							placement='left'
							content='If you face any issues during the use of this App, please report it to us here.'
						>
							<button
								className={`stake-btn ${
									currentWTPage === (address ? 10 : 7) ? "remove-fade-out" : ""
								}`}
							>
								Report issue
							</button>
						</WalkthroughBubble>
					</a>
				</div>
			</div>
			<Balance />
		</div>
	);
};

export default RightPane;
