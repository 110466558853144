import { chainInfo } from '../utils/chainInfo';
import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { useSetRecoilState } from 'recoil';
import { walletState } from '../utils/walletState';
import { coinConvert } from '@stakeordie/griptape.js';

import { API_URL, sleep } from "../utils/constants";
import { useContext } from 'react';
import { UserContext } from '../context/user-context';

export const useDisconnetWallet = () => {

  const { resetBalances } = useContext(UserContext);

  const setWalletState = useSetRecoilState(walletState);
  return () => {
    sessionStorage.setItem('isLoggedIn', 'false');
    resetBalances();
    setWalletState({
      client: undefined,
      address: undefined,
      shortAddress: undefined,
      balance: undefined,
    })
  }
}

export const useConnectWallet = () => {
  const { setIsLoggingIn } = useContext(UserContext);
  const setWalletState = useSetRecoilState(walletState);
  return async () => {
    setIsLoggingIn(true);
    sessionStorage.setItem('isLoggedIn', 'true');
    await sleep(1);
    await (window as any).keplr?.experimentalSuggestChain(chainInfo);
    const chainId = 'juno-1';
    await (window as any).keplr?.enable(chainId);
    if ((window as any).keplr === undefined) return;
    const offlineSigner = await (window as any).getOfflineSignerAuto(chainId);

    const accounts = await offlineSigner.getAccounts();

    const wasmChainClient = await SigningCosmWasmClient.connectWithSigner(
      API_URL,
      offlineSigner
    );

    const accountBalance = await wasmChainClient.getBalance(accounts[0].address, "ujuno");

    setWalletState({
      address: accounts[0].address,
      shortAddress: (accounts[0].address).substr(0, 8)
        + '...'
        + (accounts[0].address).substr((accounts[0].address).length - 3, 3)
      ,
      balance: coinConvert(accountBalance.amount as string, 6, 'human'),
      client: wasmChainClient,
    })
    setIsLoggingIn(false);
  }

}
