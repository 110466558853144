import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import {denomConst} from '../../utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { stakingContract } from "../../contracts/staking_contract";
import { stakingToken } from "../../contracts/staking_token";
import { bondedToken } from "../../contracts/bonded_token";
import DefiInput from "../defi/defi-input/DefiInput";
import bjuno from "../../assets/img/bjuno.png";
import { useRecoilValue } from 'recoil'
import { walletState } from '../../utils/walletState'
import sejuno from "../../assets/img/sejuno.png";
import { UserContext } from '../../context/user-context'
import './convert.css'
import { ToastContainer, toast } from 'react-toastify';
import LoadingModal from '../../components/LoadingModal/LoadingModal';


 
const Convert = (props:any) => {
  const {balance, client, address} = useRecoilValue(walletState);
  const [swapToken, setSwapToken] = useState('bjuno')
  const [bjunoInputAmount, setbJunoInputAmount] = useState('');
  const [sejunoInputAmount, setseJunoInputAmount] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [eqAmountMessage, setEqAmountMessage] = useState('');
   const {seJunoBalance, bJunoBalance,rate,bjunorate, token2For1Amount, token1For2Amount, 
        getToken1ForToken2, getToken2ForToken1, token1For2Fees, token2For1Fees, junoPriceUsd,getSeJunoBalance,getBJunoBalance} = useContext(UserContext);
    const customId1 = "custom-id-1";
    const customId2 = "custom-id-2";
  const handleInputChange = async(e:any)=>{
        console.log(e.target.name);
        
        const value = (e.target.value);
        setInputValue(value)
        if(swapToken==='bjuno'){
            setbJunoInputAmount(value);
            

          setseJunoInputAmount((Number(value)*Number(bjunorate)/Number(rate)).toFixed(4));
        }else{
          setseJunoInputAmount(value);
          setbJunoInputAmount((Number(value)*Number(rate)/Number(bjunorate)).toFixed(4));
        }
      }

     const handleSwitch =()=>{
      if(swapToken==='bjuno'){
        setSwapToken('sejuno')
    }else{
        setSwapToken('bjuno')
    }
    setseJunoInputAmount('');
    setbJunoInputAmount('');
    setInputValue('');
    }

    const CustomToastWithLink = (hash:string) => (
  <div> 
    <a style={{color:"blue",textDecoration:"none"}} href={"https://www.mintscan.io/juno/txs/"+hash} target="_blank" rel="noopener noreferrer">Click to view your transaction details {hash.substr(0,5) + '...' +hash.substr(hash.length-3,3)} </a> 
  </div>
);

    const convertBjunoxTojunox = async () => {
    // var inputVal = (document.getElementById("stake-juno-input") as HTMLInputElement).value;


    try {

      
        setEqAmountMessage(`(convering ${(Number(props.inputAmount)*rate).toLocaleString()} Bjuno to )`)
        setLoading(true);
        const res=await client?.execute(
          (address as string),
          bondedToken.at,
          {
          send: {
            amount: (parseFloat(inputValue)*1_000_000).toFixed(0),
            contract: stakingContract.at,
            msg:  "eyJjb252ZXJ0Ijp7fX0="
          }
        },
        {amount: [], gas: "1100000"}, // custom fees
        "",
        []
        );
      
      setLoading(false);
      toast.success(`${inputValue} amount of bJUNO successfully converted to seJUNO.`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        toast.info(CustomToastWithLink(res?(res.transactionHash).toString():"no hash"),{
          position: "top-right",
        hideProgressBar: false,
        autoClose: 10000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        
      getSeJunoBalance();
      getBJunoBalance();
      setbJunoInputAmount("");
      setseJunoInputAmount("");
      
    } catch (error) {
      console.log(error);
      
      setLoading(false);
      // handleErrorMessage(error);
        return;
    }
  }

  const convertJunoxToBjunox = async () => {
    // var inputVal = (document.getElementById("stake-juno-input") as HTMLInputElement).value;


    try {

      
        setEqAmountMessage(`(Converting ${(Number(props.inputAmount)*rate).toLocaleString()} juno)`)
        setLoading(true);
        const res=await client?.execute(
          (address as string),
          stakingToken.at,
          {
          send: {
            amount: (parseFloat(inputValue)*1_000_000).toFixed(0),
            contract: stakingContract.at,
            msg: "eyJjb252ZXJ0Ijp7fX0="
          }
        },
        {amount: [], gas: "1100000"}, // custom fees
        "",
        []
        );
      
      setLoading(false);
      toast.success(`${inputValue} amount of seJUNO successfully converted to bJUNO.`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        toast.info(CustomToastWithLink(res?(res.transactionHash).toString():"no hash"),{
          position: "top-right",
        hideProgressBar: false,
        autoClose: 10000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      getSeJunoBalance();
      getBJunoBalance();
      setbJunoInputAmount("");
      setseJunoInputAmount("");
      
    } catch (error) {
      console.log(error);
      
      setLoading(false);
      // handleErrorMessage(error);
        return;
    }
  }
  const maxHandler =(amt:string)=>{
      console.log("function call");
        setInputValue(amt);
         if(swapToken==='bjuno'){
            setbJunoInputAmount(amt);
            

          setseJunoInputAmount((Number(amt)*Number(bjunorate)/Number(rate)).toFixed(4));
        }else{
          setseJunoInputAmount(amt);
          setbJunoInputAmount((Number(amt)*Number(rate)/Number(bjunorate)).toFixed(4));
        }
    }
  return (<>
    <div className="stake-menu-header">
        <div className='stake-menu-heading stake-menu__item'>Convert {swapToken==='sejuno'?" seJUNO ":" bJUNO "} To {swapToken==='sejuno'?" bJUNO ":" seJUNO "}</div>

    </div>
    <div  style={{
        'flexDirection':swapToken==='bjuno'?'column':'column-reverse'
    }} className="convert-input-wrapper-container" >
      <DefiInput  onInputChange={handleInputChange} 
         value={inputValue?bjunoInputAmount:''} 
         convertTab={true}
         activeToken={swapToken}
         setValue={maxHandler}
         tokenName='bJUNO' tokenBalance={bJunoBalance}  logoSrc={bjuno} name='li-1'
         showError={swapToken==='bjuno'&& inputValue && Number(bjunoInputAmount)>Number(bJunoBalance)}/>
         <div onClick={handleSwitch} className={`exchange-arrow-wrapper ${swapToken==='bjuno'?'exchange-icon-rotate':''}`}>
        <FontAwesomeIcon color='gold' icon={faArrowUp} />
        <FontAwesomeIcon icon={faArrowDown} />
      </div>

      <DefiInput  onInputChange={handleInputChange} 
      convertTab={true}
      setValue={maxHandler}
      activeToken={swapToken}
        value={inputValue?sejunoInputAmount:''} tokenName='seJUNO'
         tokenBalance={seJunoBalance}  logoSrc={sejuno} name='li-2'
         showError={swapToken==='sejuno'&&inputValue && Number(sejunoInputAmount)>Number(seJunoBalance)}/>
        
      </div>
       <div className='convert-rate-value-wrapper'>
        <div className='convert-rate'>
       {swapToken==='bjuno'&&` 1 bJUNO ≈ ${bjunoInputAmount?(Number(sejunoInputAmount)/Number(bjunoInputAmount)).toLocaleString():(Number(bjunorate)/Number(rate)).toLocaleString()} seJUNO ≈ ${'$'+((Number(bjunorate)/Number(rate))*Number(junoPriceUsd)).toFixed(5)}`}
       {swapToken!=='bjuno'&&` 1 seJUNO ≈ ${sejunoInputAmount?(Number(bjunoInputAmount)/Number(sejunoInputAmount)).toLocaleString():(Number(rate)/Number(bjunorate)).toLocaleString()} bJUNO ≈ ${'$'+((Number(rate)/Number(bjunorate))*Number(junoPriceUsd)).toFixed(5)}`}
        </div>
        <div className='convert-value'>
        {swapToken==='bjuno'&&`$${inputValue?(Number(bjunoInputAmount)*(Number(bjunorate)/Number(rate))*Number(junoPriceUsd)).toLocaleString():'0.00'}`}
        {swapToken==='sejuno'&&`$${inputValue?(Number(sejunoInputAmount)*(Number(rate)/Number(bjunorate))*Number(junoPriceUsd)).toLocaleString():'0.00'}`}
        </div>
      </div>
      <button 
        onClick={swapToken==='sejuno'?convertJunoxToBjunox:convertBjunoxTojunox}
        className='stake-btn' 
        disabled={
          !bjunorate || !rate || !bjunoInputAmount || !Number(bjunoInputAmount) || (swapToken==='bjuno'?Number(bjunoInputAmount)>Number(bJunoBalance):Number(sejunoInputAmount)>Number(seJunoBalance))
        }
      >
        Convert
      </button>
      <LoadingModal open={loading} >

          {"Converting"}{" "+inputValue+" "}
         {swapToken==='sejuno'?" seJUNO ":" bJUNO "}{" To "}{swapToken==='sejuno'?bjunoInputAmount:sejunoInputAmount}{swapToken==='sejuno'?" bJUNO":" seJUNO "}
                 
    </LoadingModal>
    </>
  )
}

export default Convert