import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../components/loading_spinner/LoadingSpinner";
import Timer from "../../utils/timer/Timer";
import { UserContext } from "../../context/user-context";
import {VALIDATOR_MAP} from '../../utils/constants';
import {denomConst} from '../../utils/constants';
import './index.css';
import { stakingContract } from "../../contracts/staking_contract";
import { rewardsContract } from "../../contracts/rewards_contract";
import { bondedToken } from "../../contracts/bonded_token";


function ContractInfo() {
  const [progress, setProgress] = useState<any>(0);
  const [timeLeft, setTimeLeft] = useState<Date>(new Date());
  const time = new Date();
  time.setSeconds(time.getSeconds() + 120); 

  const {info, rate, activeWindow, isLoading, fetchWindow, isWindowFetching} = useContext(UserContext);

  useEffect(()=>{
    let sec = activeWindow.time_to_close - Math.floor(Date.now() / 1000);
    console.log(sec);
    
    if (sec<0) {
      sec=0;
    }
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec); 
    setTimeLeft(time);
    setProgress(sec);  
    console.log(timeLeft, progress);
    
  },[activeWindow.time_to_close,progress]);

  const handleTimerExpire=()=>{
    const oldWindowID = activeWindow.id;
    console.log("expired");
    setProgress(0);
    let reloadTime = 15000;
    const expTimer = () =>{
      if(oldWindowID!==activeWindow.id){
        fetchWindow();
      }
    }
    setInterval(()=>{
      expTimer();
      reloadTime = reloadTime*1.1;
    }, reloadTime)

  }

  function shortenAddress(address:string) {
    return address.substr(0,8) + '...' +address.substr(address.length-3,3);
  }

  return (
    <>
      <div className="wrapper">
        <div className="contract-info-wrapper">
      
          <h2 className="info-heading">Contract info</h2>
          <div className="info-card card">
            <div className="info-card-content">
              <div>Total {denomConst.tokenDenom} staked</div>
              {isLoading?<LoadingSpinner/>:<div className="amount-with-unit">
                <span>{(parseFloat(info.total_staked)/1000000).toLocaleString() ?? `-`} </span> 
                <span>{denomConst.tokenDenom}</span></div>}
            </div>
            <div className="info-card-content">
              <div>{denomConst.tokenDenom} under withdraw</div>
              {isLoading?<LoadingSpinner/>:<div className="amount-with-unit">
                <span>{(parseFloat(info.juno_under_withdraw)/1000000 + (parseFloat(activeWindow.sejuno_amount)/1000000)*rate).toLocaleString() ?? `-`} </span> 
                <span>{denomConst.tokenDenom}</span></div>}
            </div>
            <div className="info-card-content">
              <div>Contract manager</div>
              {isLoading?<LoadingSpinner/>:<div>
                <a className="val-address-link" target='_blank'
                href={`https://www.mintscan.io/juno/account/${info?.admin}`}>
                  { (info.admin === null || info.admin === undefined) ? `-` : (shortenAddress(info.admin))}
                </a>
              </div>}
            </div>
            {/* <div className="info-card-content">
              <div>Current {denomConst.derTokenDenom} unbonding</div>
              {isLoading?<LoadingSpinner/>:<div>{(parseFloat(info.sejuno_in_contract)/1000000).toLocaleString() ?? `-`} {denomConst.derTokenDenom}</div>}
            </div> */}
            <div className="info-card-content">
              <div>{denomConst.derTokenDenom} token</div>
              {isLoading?<LoadingSpinner/>:<a className="val-address-link" target='_blank'
              href={`https://www.mintscan.io/juno/account/${info?.sejuno_token}`}>
                { (info.sejuno_token === null || info.sejuno_token === undefined) ? `-` : (shortenAddress(info.sejuno_token))}
              </a>}
            </div>
            <div className="info-card-content">
              <div>Staking contract</div>
              {isLoading?<LoadingSpinner/>:<a className="val-address-link" target='_blank'
               href={`https://www.mintscan.io/juno/account/${stakingContract.at}`}>
                { (stakingContract.at === null || stakingContract.at === undefined) ? `-` : (shortenAddress(stakingContract.at))}
              </a>}
            </div>
            <div className="info-card-content">
              <div>{"bJUNO"} token</div>
              {isLoading?<LoadingSpinner/>:<a className="val-address-link" target='_blank'
              href={`https://www.mintscan.io/juno/account/${bondedToken.at}`}>
                { (bondedToken.at === null || bondedToken.at === undefined) ? `-` : (shortenAddress(bondedToken.at))}
              </a>}
            </div>
            <div className="info-card-content">
              <div>Rewards contract</div>
              {isLoading?<LoadingSpinner/>:<a className="val-address-link" target='_blank'
               href={`https://www.mintscan.io/juno/account/${rewardsContract.at}`}>
                { (rewardsContract.at === null || rewardsContract.at === undefined) ? `-` : (shortenAddress(rewardsContract.at))}
              </a>}
            </div>
          </div>

          <h2 className="info-heading">Validators</h2>
          <div className="info-card card validators-card">
            <div className="validators-heading ">
              <div>NAME</div>
              <div>AMOUNT</div>
            </div>
            <div className="validators-list" >{isLoading?<LoadingSpinner style={{'margin':'10px auto'}} />:
            info.validator_set?.map((item:any, index:any) => {
              const validator: any = VALIDATOR_MAP.find((v)=>v.address===item.address);
              return <div className="info-card-content jcs">
              <div className="list-numberings"> {'('+(index+1)+')'} </div>
              <div>
                <a className="val-address-link"  target='_blank' href={`https://www.mintscan.io/juno/validators/${item?.address}`}>{validator?validator.name : item.address }</a>
            </div>
            <div className="jcs-child__value amount-with-unit">
              <span>{(parseFloat(item.staked)/1000000).toLocaleString()} </span>
              <span>{denomConst.tokenDenom}</span></div>
            </div>
            }
              
            )
            }
            </div>
          </div>

          <h2 className="info-heading">Active Window</h2>
          <div className="info-card card">
            <div className="info-card-content">
              <div>Window ID</div>
              {isLoading?<LoadingSpinner/>:<div>{activeWindow.id ?? `-`}</div>}
            </div>
            {/* <div className="info-card-content">
              <div>{denomConst.derTokenDenom} to be withdrawn in window</div>
              {isLoading?<LoadingSpinner/>:<div>{(parseFloat(activeWindow.sejuno_amount)/1000000).toLocaleString() ?? `-`} {denomConst.derTokenDenom}</div>}
            </div> */}
            <div className="info-card-content">
              <div>Time to trigger window</div>
              {!progress||isLoading?`${isWindowFetching?'Fetching window...':'-'}`:
              <Timer expiryTimestamp={timeLeft}
              totalTime={345600}
               wrapperClassName="progress-bar-wrapper"
               barClassName="progress-bar"
               onExpire={handleTimerExpire}
               inlineTag={true}
               />}
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default ContractInfo;
