import React, { useState } from 'react'
import Modal from "react-modal";
import logo from '../../assets/img/flatwhite.png'
import './LoadingModal.css'

const LoadingModal = (props:any) => {

    const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    
        <Modal isOpen={props.open}
        onRequestClose={()=>setMenuIsOpen(false)}
        contentLabel="My dialog"
        className="loading-modal"
        overlayClassName="myoverlay"
        closeTimeoutMS={300}>
          <div className='loading-modal-wrapper'  >
          <div className="loading-modal-content-wrapper"  >
          <img src={logo} />
          <div>
          {props.children}
        </div>
        </div>
          
        </div>
           
        </Modal>
    
  )
}

export default LoadingModal